import React from 'react';
import { useWatch } from 'react-hook-form';

import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { I18n } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useIsCartValid } from 'public/components/default_template/online_ordering/checkout/useIsCartValid';
import { useCart } from 'public/components/online_ordering/CartContext';
import { OrderError, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import useAdyenPayments from './useAdyenPayments';

type Props = {
  setShowAdyenOverlay: (show: boolean) => void
  orderErrorRef: React.RefObject<OrderError | null>
};

type GuestInfo = {
  yourInfoEmail: string
  yourInfoFirstName?: string
  yourInfoLastName?: string
  yourInfoPhone?: string
}

type BaseProps = {
  rxGuid: string
  i18n: I18n
  cartGuid: string
  checkAmount: number
  tipAmount: number
  guestInfo: GuestInfo
  hasError: boolean
  blockRedirect: boolean
  onPaymentSubmitted: () => void
  onPaymentFailed: () => void
  onPaymentCompleted: (intentID: string) => void
};

const AdyenPaymentWrapper = ({ rxGuid, i18n, cartGuid, checkAmount, tipAmount, guestInfo, hasError, blockRedirect, onPaymentSubmitted, onPaymentFailed, onPaymentCompleted }: BaseProps) => {
  const tracker = useTracker();
  const { isCartValid } = useIsCartValid();

  return (
    <AdyenPayment
      payment={{ checkAmount, tipAmount, emailAddress: guestInfo.yourInfoEmail }}
      onEvent={event => {
        if(event === 'PAYMENT_SUBMITTED') {
          onPaymentSubmitted();
        }
        if(
          [
            'POLLING_ERROR',
            'POLLING_TIMEOUT',
            'PAYMENT_REJECTED'
          ].includes(event) || hasError
        ) {
          onPaymentFailed();
        }
      }}
      config={{
        currency: i18n.currency,
        locale: i18n.locale,
        country: i18n.country,
        guid: rxGuid,
        channel: 'ONLINE_ORDERING',
        attributes: cartGuid ? { cartGuid } : undefined
      }}
      preSubmitEvent={async () => {
        const { yourInfoFirstName, yourInfoLastName, yourInfoEmail, yourInfoPhone } = guestInfo;
        return await isCartValid(true, {
          firstName: yourInfoFirstName,
          lastName: yourInfoLastName,
          email: yourInfoEmail,
          phone: yourInfoPhone
        });
      }}
      shouldBlockRedirect={data => {
        tracker.track('adyen_should_block_redirect', {
          cartGuid,
          url: data?.url,
          method: data?.method
        });

        return blockRedirect;
      }}
      onActionHandled={data => {
        tracker.track('adyen_action_handled', {
          cartGuid,
          componentType: data?.componentType,
          actionDescription: data?.actionDescription
        });
      }}
      onPaymentCompleted={paymentIntentID => {
        tracker.track('adyen_payment_completed', {
          cartGuid,
          paymentIntentID
        });

        onPaymentCompleted(paymentIntentID);
      }} />
  );
};

const AdyenPaymentForm = ({ setShowAdyenOverlay, orderErrorRef }: Props) => {
  const { ooRestaurant } = useRestaurant();
  const { cartGuid } = useCart();
  const { orderTotal } = useCheckout();
  const { tipAmount } = usePayment();
  const { intlOoAdyenBlockRedirect } = useFlags();

  const { placeAdyenOrder } = useAdyenPayments(setShowAdyenOverlay);

  const guestInfo = {
    yourInfoFirstName: useWatch({ name: 'yourInfoFirstName' }),
    yourInfoLastName: useWatch({ name: 'yourInfoLastName' }),
    yourInfoEmail: useWatch({ name: 'yourInfoEmail' }),
    yourInfoPhone: useWatch({ name: 'yourInfoPhone' })
  };
  const i18n = ooRestaurant?.i18n;
  const guid = ooRestaurant?.guid;

  if(!i18n || !guid || !cartGuid) {
    return null;
  }

  return (
    <AdyenPaymentWrapper
      rxGuid={guid}
      i18n={i18n}
      cartGuid={cartGuid}
      checkAmount={orderTotal - tipAmount}
      tipAmount={tipAmount}
      guestInfo={guestInfo}
      hasError={!!orderErrorRef.current}
      blockRedirect={intlOoAdyenBlockRedirect}
      onPaymentSubmitted={() => setShowAdyenOverlay(true)}
      onPaymentFailed={() => setShowAdyenOverlay(false)}
      onPaymentCompleted={(paymentIntentID: string) => placeAdyenOrder(paymentIntentID)} />
  );
};

export default AdyenPaymentForm;
