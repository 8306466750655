import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_COLORS } from '@toasttab/sites-components';
import classnames from 'classnames';

import { LoyaltyFeatures } from 'src/apollo/onlineOrdering';
import { ButtonType, MenuConfig } from 'src/apollo/sites';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { overrideConfigBasedOnTemplate } from 'src/public/components/default_template/menu_section/menuStylingOverrides';
import { useThemeColorScheme } from 'src/public/components/default_template/meta/useTheme';
import { PwlessAuth } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { CAInfo, ToastTOSAndPrivacy } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/legalUtils';
import { AuthenticationSource } from 'src/public/components/default_template/online_ordering/checkout/checkoutUtils';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';
import Image from 'src/shared/components/common/Image';
import Button from 'src/shared/components/common/button';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { alertError, alertSuccess } from 'src/shared/js/alertUtils';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

import { LoadingSpinner } from 'shared/components/common/loading_spinner/LoadingSpinner';

import { LoyaltyProgramDetails, LoyaltySignupModal } from 'public/components/default_template/offers/LoyaltySignupModal';
import { LoyaltyIcon, SmallRightArrow, LoyaltyDiamondIcon } from 'public/components/default_template/offers/OffersIcons';

const getLoyaltyFeatureType = (loyaltyFeatures: (LoyaltyFeatures | null)[] | undefined | null, type: string) => {
  return loyaltyFeatures?.find(feature => feature?.tags?.includes(type));
};

export enum LoyaltyCardType {
  AccrualBonus = 'AccrualBonus',
  OrderConfirmationPage = 'OrderConfirmationPage',
  Signup = 'Signup'
}

export const LoyaltyCard = ({ type = LoyaltyCardType.Signup }: {type?: LoyaltyCardType}) => {
  const { restaurant: { config, ooConfig, meta }, ooRestaurant, ooPromoBanners } = useRestaurant();
  const { track } = useTracker();
  const templateOverrides = useMemo(() => overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config), [config]);
  const getColorFromTheme = useThemeColorScheme();
  const { isOpen: isLoyaltyModalOpen, onClose: onCloseLoyaltyModal, onOpen: onOpenLoyaltyModal } = useModal();
  const { isOpen: isAuthModalOpen, onClose: onCloseAuthModal, onOpen: onOpenAuthModal } = useModal();
  const [isShowingLegal, setIsShowingLegal] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const loyaltyConfig = ooRestaurant?.loyaltyConfig;
  const { loyaltyFeatures, programDescription, hasLoyaltyAccount, signupForLoyalty, loadingLoyaltyFeatures, loadingLoyaltyAccount, loyaltyLoading, pointsBalance } = useLoyalty();
  const { customer } = useCustomer();
  const [showSuccessfulSignupCard, setShowSuccessfulSignupCard] = useState(false);
  const isSignedIn = useMemo(() => !!customer, [customer]);
  const accrualMessage = useMemo(() =>
    getLoyaltyFeatureType(loyaltyFeatures, 'ACCRUAL')
  , [loyaltyFeatures]);
  const signUpBonus = useMemo(() =>
    getLoyaltyFeatureType(loyaltyFeatures, 'SIGNUP')
  , [loyaltyFeatures]);

  // captures `## bonus point(s)`/ `## bonus visit(s)`
  const signUpBonusPoints = useMemo(() => signUpBonus?.message?.match(/((\d+)\s+bonus\s+(\S+))/m)?.[0], [signUpBonus?.message] );
  const [hasFinishedAuth, setHasFinishedAuth] = useState(false);
  const loyaltyBanner = ooPromoBanners?.loyaltyBanners?.[0];

  const [illustrationColor, backgroundColor, textColor, borderColor] = getColorFromTheme(theme => {
    return [
      theme.colorOverrides?.offerColorOverrides?.illustration ?? theme.colorScheme.background.primary,
      theme.colorOverrides?.onlineOrderingMenu?.cardBackground ?? theme.colorScheme.surface.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.itemTitle ?? theme.colorScheme.text.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.cardBorder ?? theme.colorScheme.border.default
    ];
  },
  [
    ooConfig?.promoCodeConfig?.backgroundColor ?? meta.primaryColor ?? DEFAULT_COLORS.primary,
    config.ooConfig?.colors?.background ?? DEFAULT_COLORS.background ?? '#FFFFFF',
    config.ooConfig?.colors?.primaryText ?? DEFAULT_COLORS.text,
    templateOverrides?.borderColor
  ]);

  const isAccrualBonusCard = type === LoyaltyCardType.AccrualBonus;
  const isOrderConfirmationPage = type === LoyaltyCardType.OrderConfirmationPage;

  const headerText = useMemo(() => {
    if(isAccrualBonusCard && loyaltyBanner) {
      return `${ooPromoBanners?.loyaltyBanners[0]?.bannerText} right now!`;
    }
    if(!isOrderConfirmationPage) {
      return accrualMessage?.message;
    }
    if(showSuccessfulSignupCard) {
      return 'Loyalty signup successful!';
    }
    return isSignedIn && hasLoyaltyAccount ? `You have ${pointsBalance} points.` : 'Don\'t miss out on rewards!';
  }, [isAccrualBonusCard, loyaltyBanner, isOrderConfirmationPage, showSuccessfulSignupCard, isSignedIn, hasLoyaltyAccount, pointsBalance, ooPromoBanners?.loyaltyBanners, accrualMessage?.message]);

  const subHeaderText = useMemo(() => {
    if(isAccrualBonusCard && loyaltyBanner) {
      return 'Place an order to start earning!';
    }
    if(showSuccessfulSignupCard) {
      return `${pointsBalance} rewards points earned`;
    }
    if(isSignedIn && hasLoyaltyAccount) {
      return isOrderConfirmationPage ? 'You\'ll earn toward rewards when this order is picked up.' : 'View and redeem rewards at checkout';
    }
    if(signUpBonus) {
      // Removes the period at the end that is added by the loyalty API
      return signUpBonus.message?.replace(/\./g, '');
    }
    return 'Sign in / Sign up to start earning';
  }, [isAccrualBonusCard, loyaltyBanner, showSuccessfulSignupCard, isSignedIn, hasLoyaltyAccount, signUpBonus, pointsBalance, isOrderConfirmationPage]);

  const onClick = useCallback(() => {
    if(isSignedIn) {
      onOpenLoyaltyModal();
    } else {
      track('Started Authentication', { source: isOrderConfirmationPage ? AuthenticationSource.LoyaltyCardOnConfirmationPage : AuthenticationSource.LoyaltyCard });
      onOpenAuthModal();
    }
  }, [isSignedIn, onOpenLoyaltyModal, onOpenAuthModal, isOrderConfirmationPage, track]);

  const onCloseAuthModalCb = useCallback(() => {
    if(!hasFinishedAuth) {
      onCloseAuthModal();
    }
  }, [hasFinishedAuth, onCloseAuthModal]);

  useEffect(() => {
    if(hasFinishedAuth && isSignedIn && !loadingLoyaltyAccount) {
      setHasFinishedAuth(false);
      onCloseAuthModal();
      alertSuccess(hasLoyaltyAccount ? `Welcome back, ${customer?.firstName}. Continue Earning!` : `Welcome back, ${customer?.firstName}`, 'overModals');
      // if we're on the order confirmation page, only open the loyalty modal if we need to sign up still
      if(isOrderConfirmationPage && hasLoyaltyAccount) return;
      onOpenLoyaltyModal();
    }
  }, [customer?.firstName, hasLoyaltyAccount, isSignedIn, loadingLoyaltyAccount, onCloseAuthModal, onOpenLoyaltyModal, hasFinishedAuth, isOrderConfirmationPage]);

  const onCloseModal = useCallback(() => {
    setIsShowingLegal(false);
    setAcceptedTerms(false);
    onCloseLoyaltyModal();
  }, [onCloseLoyaltyModal]);

  const signUpForLoyaltyCb = useCallback(async () => {
    if(customer) {
      const { data } = await signupForLoyalty(customer.id);
      if(data?.loyaltySignup.__typename === 'LoyaltySignupResponse') {
        alertSuccess(signUpBonus ? `Signed up for loyalty, ${signUpBonusPoints} earned!` : 'Signed up for loyalty!', 'overModals');
        onCloseModal();
        setShowSuccessfulSignupCard(true);
      } else {
        alertError('Error occurred while signing up for loyalty', 'overModals');
      }
    }
  }, [customer, onCloseModal, signUpBonus, signUpBonusPoints, signupForLoyalty]);

  const [loyaltyModalHeight, setLoyaltyModalHeight] = useState(0);
  useEffect(() => {
    if(isLoyaltyModalOpen) {
      setLoyaltyModalHeight(Math.max(loyaltyModalHeight, document.getElementById('modal-content')?.clientHeight || 0));
    }
  }, [isLoyaltyModalOpen, loyaltyModalHeight]);

  if(!loyaltyConfig?.loyaltySignupEnabled || !accrualMessage || loadingLoyaltyFeatures || !programDescription ||
    isAccrualBonusCard && ( !isSignedIn || !hasLoyaltyAccount)
  ) {
    return null;
  }

  if(isOrderConfirmationPage && loyaltyLoading) {
    return <div className="loyaltyLoading" data-testid="loyalty-loading-spinner"><LoadingSpinner color={illustrationColor} /></div>;
  }
  return (
    <>
      <button
        className={classnames('offerAndLoyaltyCard',
          templateOverrides.borderStroke,
          templateOverrides?.roundedCorner,
          templateOverrides?.dropShadow)}
        style={{ borderColor, backgroundColor, color: textColor }}
        onClick={onClick}
        disabled={isOrderConfirmationPage && isSignedIn && hasLoyaltyAccount}
        aria-disabled={isOrderConfirmationPage && isSignedIn && hasLoyaltyAccount}
        aria-labelledby="loyalty-card-label"
        data-testid={'loyalty-offer-card'}>
        <div className="offerInfo" id="loyalty-card-label">
          <div className="offerText" data-testid="offer-text">{headerText}</div>
          <div className="applyText">
            <span>{subHeaderText}</span>
            {!hasLoyaltyAccount && <SmallRightArrow color={textColor} />}
          </div>
        </div>
        <div
          className="offerIllustration"
          data-testid={'offer-illustration-loyalty'}
          style={{ background: `linear-gradient(270deg, ${illustrationColor}22, transparent)` }}>
          {isAccrualBonusCard ?
            <div className="scaledIllustrationContainer"><LoyaltyDiamondIcon color={illustrationColor} /></div> :
            <LoyaltyIcon color={illustrationColor} />}
        </div>
      </button>
      <Modal
        className="offerModalWrapper"
        isOpen={isLoyaltyModalOpen}
        onClose={onCloseModal}>
        <ModalOverlay fadeIn fadeOut />
        {isShowingLegal ?
          <TermsAndConditionsModal
            closeLegal={() => setIsShowingLegal(false)}
            modalHeight={loyaltyModalHeight}
            onAgree={() => {
              setAcceptedTerms(true);
              setIsShowingLegal(false);
            }} /> :
          <LoyaltySignupModal
            illustrationColor={illustrationColor}
            openLegal={() => setIsShowingLegal(true)}
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
            signupForLoyalty={signUpForLoyaltyCb} />}
      </Modal>
      <Modal isOpen={isAuthModalOpen} onClose={onCloseAuthModalCb}>
        <ModalOverlay />
        <PwlessAuth
          source={isOrderConfirmationPage ? AuthenticationSource.LoyaltyCardOnConfirmationPage : AuthenticationSource.LoyaltyCard}
          skipLoginToast={true}
          customLoginHeader="Sign in to get started"
          secondaryCTAText="Loyalty Program Details"
          secondaryContent={<LoyaltyProgramDetails illustrationColor={illustrationColor} />}
          onSuccessfulLogin={() => setHasFinishedAuth(true)} />
      </Modal>
    </>
  );
};

const TermsAndConditionsModal = ({ closeLegal, onAgree, modalHeight }: {closeLegal: () => void, onAgree: () => void, modalHeight: number}) => {
  const isIntlRestaurant = useIsIntlRestaurant();
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  return (
    <ModalContent contentClassName="modalContentV2 loyalty fullScreenMobile" style={!isMobile ? { height: `${modalHeight}px` } : undefined}>
      <div className="modalHeader withBackArrow">
        <button
          aria-label="Return to loyalty sign up"
          className="backArrow"
          data-testid="legal-back-button"
          onClick={closeLegal}>
          <Image alt="Back" src="icons/arrow-left-gray.svg" />
        </button>
        <ModalCloseButton />
      </div>
      <div className="body terms">
        <div className="headerContent centered">
          <div className="title">Terms & Conditions</div>
        </div>
        <p data-testid="loyalty-legal-text" className="termsText">
          <LoyaltyTermsAndConditions isIntlRestaurant={isIntlRestaurant} />
        </p>
        <Button variant={ButtonType.Primary} onClick={onAgree} className="termsButton">I agree</Button>
      </div>
    </ModalContent>
  );
};

export const LoyaltyTermsAndConditions = ({ isIntlRestaurant }: {isIntlRestaurant: boolean}) => {
  return (
    <>
      { isIntlRestaurant ?
        <>
        By joining a loyalty program, you agree to participate and to be contacted using the contact information you provide. Loyalty transactions are tied to your
        Toast account, if applicable. You may receive SMS messages if a mobile number is provided. Message & data rates may apply, msg frequency varies.
        Reply STOP to opt out. Rewards program is subject to <ToastTOSAndPrivacy /> and
        Merchant&apos;s Terms and Policies.
        </> :
        <>
        By joining a loyalty program, you agree to participate and to be contacted using the contact information you provide.
        Loyalty transactions are tied to your Toast account, if applicable. You may receive SMS messages if a mobile number is
        provided. Message & data rates may apply, msg frequency varies. Reply STOP to opt out. Rewards program is subject to <ToastTOSAndPrivacy />{' '}
        and Merchant&apos;s{' '} Terms and Policies. <CAInfo />
        </>}
    </>
  );
};
