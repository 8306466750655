import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useGuestWalletQuery } from 'src/apollo/onlineOrdering';
import useQueryString from 'src/lib/js/hooks/useQueryString';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

// Lat/long of Cambridge, MA
export const pilotAreaDefaultSearchLatLong = {
  latitude: 42.354639,
  longitude: -71.046555
};

// Massachusetts bounding box coordinates
const massachusettsBounds = {
  minLat: 41.237964, // Southernmost latitude
  maxLat: 42.886589, // Northernmost latitude
  minLng: -73.508142, // Westernmost longitude
  maxLng: -69.928393 // Easternmost longitude
};

// Function to check if a coordinate is within the pilot area
function isWithinMassachusetts(lat: number, lng: number) {
  return (
    lat >= massachusettsBounds.minLat &&
    lat <= massachusettsBounds.maxLat &&
    lng >= massachusettsBounds.minLng &&
    lng <= massachusettsBounds.maxLng
  );
}

type LocationCoordsParam = { latitude: number, longitude: number }
const isWithinToastCashPilotDistance = (location: LocationCoordsParam) => {
  return isWithinMassachusetts(location.latitude, location.longitude);
};

export function useShowAcceptsToastCashFilter() {
  const { stpToastCashViewOo } = useFlags();
  const parsedQS = useQueryString(['filters'], ['filters']);
  const ooClient = useOOClient();
  const { customer } = useCustomer();
  const customerWalletResponse = useGuestWalletQuery({
    variables: {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // Ensure null, since we want to make sure we get the amount without stored value
      restaurantId: null
    },
    skip: !customer || !stpToastCashViewOo,
    client: ooClient
  });

  const isWithinToastCashPilotArea = useMemo(() => {
    if(typeof parsedQS.filters?.userLat === 'number' && typeof parsedQS.filters?.userLong === 'number') {
      return isWithinToastCashPilotDistance({ latitude: parsedQS.filters.userLat, longitude: parsedQS.filters.userLong });
    }
    return false;
  }, [parsedQS.filters?.userLat, parsedQS.filters?.userLong]);

  if(!stpToastCashViewOo) {
    return false;
  }

  const walletAmountWithStoredValue = Number(customerWalletResponse.data?.guest.wallet.guestCurrencyAccount.availableBalanceWithStoredValue.amount) || 0;
  const walletAmountWithoutStoredValue = Number(customerWalletResponse.data?.guest.wallet.guestCurrencyAccount.availableBalanceByRestaurant.amount) || 0;
  const storedValueAmount = walletAmountWithStoredValue - walletAmountWithoutStoredValue;
  return parsedQS.filters && 'acceptsToastCash' in parsedQS.filters || isWithinToastCashPilotArea || storedValueAmount > 0;
}
