// https://developers.google.com/tag-manager/quickstart

import { type DataLayerArgs } from 'react-gtm-module';
const Snippets = {
  tags: function({ id, events, dataLayer, dataLayerName, preview, auth }: any) {
    const gtmAuth = `&gtm_auth=${auth}`;
    const gtmPreview = `&gtm_preview=${preview}`;

    if(!id) console.warn('GTM Id is required');

    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}${gtmAuth}${gtmPreview}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar
    };
  },
  dataLayer: function(dataLayer: DataLayerArgs['dataLayer'], dataLayerName: string) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  }
};

export default Snippets;
