import React from 'react';

import { DeliveryProvider } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';

import { CAInfo, ToastTOSAndPrivacy } from 'public/components/default_template/online_ordering/account/pwlessAuth/legalUtils';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useMarketing } from 'public/components/online_ordering/MarketingContext';
import { PaymentOption } from 'public/components/online_ordering/PaymentContext';
import { Cart } from 'public/components/online_ordering/types';

const CheckoutNote = ({ showIntlPayment, paymentOption }: {showIntlPayment?: boolean, paymentOption?: PaymentOption | null}) => {
  const { smsAccountEnabled } = useMarketing();
  const international = useIsIntlRestaurant();

  if(international) {
    const payUponReceipt = paymentOption === PaymentOption.UponReceipt;
    const openingText = showIntlPayment || payUponReceipt ? 'By placing your order' : 'By continuing to payment and placing your order';
    return (
      <p data-testid="intlCheckoutLegalStatement" className="note checkoutNote" role="contentinfo">
        {openingText}, you authorise Toast and this restaurant/restaurant group to send you informational messages
        (such as order status updates and digital receipts) by SMS and email using the contact details provided. Message &
        data rates may apply, msg frequency varies. Reply STOP to opt out. Service is subject to <ToastTOSAndPrivacy />{' '}
        and Merchant&apos;s Terms and Policies apply.
      </p>
    );
  }

  // SMS is not available for international restaurants
  if(smsAccountEnabled) {
    return (
      <p data-testid="checkoutLegalStatement" className="note checkoutNote" role="contentinfo">
        By completing your order, you give Toast and this restaurant/restaurant group permission
        to send you informational text messages and emails (such as order status updates and digital receipts). Consent
        to marketing is not a condition of purchase. Message & data rates may apply, msg frequency
        varies. Reply STOP to opt out. Service is subject to <ToastTOSAndPrivacy />{' '}
        and Merchant&apos;s Terms and Policies apply. <CAInfo />
      </p>
    );
  }

  return (
    <p data-testid="checkoutLegalStatement" className="note checkoutNote" role="contentinfo">
      By completing your order, you give Toast and this restaurant/restaurant group permission
      to send you informational messages (such as order updates and digital receipts). Consent
      to marketing is not a condition of purchase. Message & data rates may apply, msg frequency
      varies. Reply STOP to opt out. Service is subject to <ToastTOSAndPrivacy />{' '}
      and Merchant&apos;s Terms and Policies apply. <CAInfo />
    </p>
  );
};

/**
 * Returns whether the confirmation page should render the uber disclaimer. Currently TDS restaurants will
 * only have one enabled provider, so checking if the Uber config is enabled is sufficient
 */
const showUberDisclaimer = (cart: Cart | null | undefined) => cart?.deliveryProviderInfo?.provider === DeliveryProvider.ToastDeliveryServices &&
  cart?.restaurant?.deliveryProviders.find(({ provider, enabled }) => enabled && provider === DeliveryProvider.Uber);

export const UberCheckoutNote = ({ selectedCheckoutMode }: { selectedCheckoutMode: boolean }) => {
  const { cart } = useCart();

  if(!(showUberDisclaimer(cart) && selectedCheckoutMode)) {
    return null;
  }

  return (
    <p data-testid="checkout-uber-disclaimer" className="note checkoutNote" role="contentinfo">
      By placing this order for delivery, you authorize Toast to share your delivery information with our delivery partner Uber.
      Use of your information by Uber is governed by{' '}
      <a href="https://www.uber.com/legal/en/document/?name=privacy-notice&country=united-states&lang=en" rel="noopener noreferrer" target="_blank">Uber&apos;s Privacy Notice</a>.
    </p>
  );
};

export default CheckoutNote;
