import React, { useState } from 'react';

import { formatCurrency, Currency as NumberUtilsCurrency } from '@toasttab/buffet-pui-number-utilities';
import { differenceInDays } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import qs from 'query-string';

import { Currency, Money, useGuestWalletQuery } from 'src/apollo/onlineOrdering';
import { useFormatMoney } from 'src/lib/js/hooks/useFormatPrice';
import { useStaticSiteContext } from 'src/public/components/app/StaticSiteContext';
import { isToastLocalRequest, isToastOrderRequest } from 'src/public/js/siteUtilities';
import Image from 'src/shared/components/common/Image';
import { useOptionalRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';


import { CreditCardRow } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import { resources } from 'config';

export const ProfileWallet = (props: { nowDate?: Date, isLoggedOut?: boolean }) => {
  if(props.isLoggedOut) {
    return <div className="accountSubheadingDisabled">My Wallet</div>;
  }

  return (
    <div className="profileWallet" data-testid="ProfileWallet" role="form" tabIndex={0}>
      <h2 className="profileWalletHeading" id="wallet">
        <strong>My Wallet</strong>
      </h2>
      <div className="profileWalletSections">
        <ToastCashBalance nowDate={props.nowDate} />
        <SavedCreditCards />
      </div>
    </div>
  );
};

const ToastCashCardLinkOrBlock = (props: { isLink: boolean, restaurantGuid?: string, children: React.ReactNode }) => {
  const staticContext = useStaticSiteContext();

  const isToastOrder = isToastOrderRequest(staticContext);
  const isToastLocal = isToastLocalRequest(staticContext);

  let entrypoint: undefined | 'toastOnlineOrdering' | 'toastLocal';
  if(isToastLocal) {
    entrypoint = 'toastLocal';
  } else if(isToastOrder) {
    entrypoint = 'toastOnlineOrdering';
  }

  if(props.isLink && entrypoint) {
    const queryStringSearch = qs.stringify({ entrypoint: entrypoint, restaurantGuid: props.restaurantGuid });
    return (
      <a
        href={`https://${resources.toastOrderHost}/wallet?${queryStringSearch}`}
        className="toastCashCard">
        {props.children}
      </a>
    );
  }
  return (
    <div className="toastCashCard">
      {props.children}
    </div>
  );
};

const ToastCashBalance = (props: { nowDate?: Date }) => {
  const { ooToastCashSpend: ooToastCashSpendEnabled, stpToastCashViewOo, stpToastCashWalletWebEnabled } = useFlags();
  const optionalRestaurantGuid = useOptionalRestaurant()?.ooRestaurant?.guid;
  const hasCustomer = Boolean(useCustomer().customer);

  const showToastCashBalance = optionalRestaurantGuid ? ooToastCashSpendEnabled : stpToastCashViewOo;

  const { data } = useGuestWalletQuery({
    skip: !hasCustomer || !showToastCashBalance,
    variables: {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // variable intentionally nullable; presence of stored value balance
      // determined by restaurant guid or its absence.
      restaurantId: optionalRestaurantGuid
    },
    // ensures fresh values if component re-mounts
    fetchPolicy: 'cache-and-network'
  });
  const formatMoney = useFormatMoney();

  const amountExpiringMessage = React.useMemo(() => {
    const expiringValue = data?.guest.wallet.guestCurrencyAccount.nearestFutureExpirableDayValue;
    if(!expiringValue) return null;
    return transformExpirationToGuestMessage(expiringValue.amount, new Date(expiringValue.expirationDate), props.nowDate ?? new Date());
  }, [data?.guest.wallet.guestCurrencyAccount.nearestFutureExpirableDayValue, props.nowDate]);

  if(!data || !showToastCashBalance) {
    return null;
  }

  const availableBalance = optionalRestaurantGuid ? data.guest.wallet.guestCurrencyAccount.availableBalanceByRestaurant : data.guest.wallet.guestCurrencyAccount.availableBalanceWithStoredValue;

  return (
    <div>
      <h3 className="walletSubheading">Your Toast Cash*</h3>
      <br />
      <div className="toastCashCardContainer" aria-labelledby="#toast-cash-funds">
        <ToastCashCardLinkOrBlock isLink={stpToastCashWalletWebEnabled} restaurantGuid={optionalRestaurantGuid}>
          <div className="toastCashContentsContainer">
            <Image alt="Toast Cash" src="icons/toast-logo-filled.svg" />
            <div id="toast-cash-funds" className="toastCashAmountContainer">
              <div className="toastCashAmount">
                {formatMoney(availableBalance)}
              </div>
              <div className="toastCashAvailableFunds">{amountExpiringMessage ? amountExpiringMessage : 'Available funds'}</div>
            </div>
            {stpToastCashWalletWebEnabled
              ?
              <Image alt="Toast Cash" src="icons/chevron-right-gray.svg" data-testid="link-chevron" />
              : null}
          </div>
        </ToastCashCardLinkOrBlock>
        <div className="toastCashDisclaimer">
          * Total value of any purchased gift cards and any promo codes you&rsquo;ve received.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://pos.toasttab.com/toast-cash-agreement">See terms.
          </a>
        </div>
      </div>
    </div>
  );
};

const SavedCreditCards = () => {
  const { customer } = useCustomer();
  const [errorMsg, setErrorMsg] = useState('');
  return (
    <div role="list" aria-label="Payment Methods">
      <h3 className="walletSubheading">Payment Methods**</h3>
      {customer?.creditCards?.length
        ?
        <div className="savedCards">
          { customer?.creditCards.map(card => <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} hideInputs={true} /> )}
        </div>
        :
        <div className="empty">Check back here after placing an order for your saved payment methods!</div>}
      {errorMsg && <div className="submitError">{errorMsg}</div>}
      <div className="savedCardsDisclaimer">
          ** Card details are only saved for restaurants located in the U.S.
      </div>
    </div>
  );
};


/**
 *
 * @param moneyAmount
 * @param expirationDate
 * @returns A message about near expiring credits.
 * Returns null if no user-facing message should be supplied
 */
function transformExpirationToGuestMessage(moneyAmount: Money, expirationDate: Date, nowDate: Date) {
  if(moneyAmount.currency !== Currency.Usd) {
    return null;
  }

  if(Number(moneyAmount.amount) <= 0) {
    return null;
  }

  const difference = differenceInDays(expirationDate, nowDate);

  if(difference < 0) {
    // If I get a time in the past, that is a smell that my client should be refreshing.
    // If client gets stale, indicated with a time in the past, it should trigger a refetch. I'm just going to avoid that complexity for now.
    // For now, we'll just show no message if that case does occur, and fall back to a default treatment.
    return null;
  }

  const moneyFormatted = `${formatCurrency({ amount: moneyAmount.amount, currency: NumberUtilsCurrency.USD }, 'en-US', { trailingZeros: false })}`;

  const daysFormatted = (function(): string {
    if(difference == 0) {
      return 'today';
    } else if(difference === 1) {
      return `in ${difference} day`;
    } else {
      return `in ${difference} days`;
    }
  })();

  return `${moneyFormatted} expiring ${daysFormatted}`;
}
