import React, { useMemo } from 'react';
import { withRouter } from 'react-router';

import { BulkCalls, MenuPage, PageConfig, SiteContentDataFragment, useSiteContentByDomainQuery, useSiteContentByMgmtGuidQuery, useSiteContentByShortUrlQuery } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import { BulkCallsContextProvider } from 'src/shared/components/common/dynamic_menus/BulkCallsContext';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import UhOh from 'shared/components/uh_oh/UhOh';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';

import { CTAData } from 'public/components/default_template/ctas';
import Footer from 'public/components/default_template/footer';
import { EditPathRoot, MainPageSections } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';


type SiteContent = NonNullable<SiteContentDataFragment>['content'] & { __typename: 'SiteContent' };

type DynamicMenuPageProps = {
  content?: MenuPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
  classNames?: string;
  bulkCalls?: BulkCalls | null
}

export const DynamicMenuPage = (props: DynamicMenuPageProps) => {
  const { restaurant } = useRestaurant();

  const backgroundColorStyle = useMemo(() => {
    return props.config?.backgroundColor
      ? { backgroundColor: props.config?.backgroundColor }
      : {};
  }, [props.config?.backgroundColor]);

  const { meta, theme } = restaurant;
  let { primaryCta = null, nonPrimaryCtas = null } = restaurant.content as SiteContent;

  if(!restaurant.content || !props.editPathRoot) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const bodyPrimaryCta = !primaryCta?.nav ? primaryCta as CTAData : null;
  const bodyNonPrimaryCtas = nonPrimaryCtas?.filter((cta: CTAData) => !cta.nav);

  return (
    <div className="defaultTemplate menuPage" data-testid="menuPage" style={backgroundColorStyle} id="main">
      <BulkCallsContextProvider bulkMenuItemIds={props.bulkCalls?.menuItems || restaurant.content.bulkCalls?.menuItems }>
        <MainPageSections
          sections={props.content?.sections || []}
          contentTheme={restaurant.content.theme}
          bodyPrimaryCta={bodyPrimaryCta}
          bodyNonPrimaryCtas={bodyNonPrimaryCtas}
          meta={meta}
          editPathRoot={props.editPathRoot} />
      </BulkCallsContextProvider>
    </div>
  );
};

export const WrappedDynamicMenuPage = React.memo(
  function WrappedDynamicMenuPage({ staticContext, content, editPathRoot, config }: Partial<RequestContextProps> & DynamicMenuPageProps) {
    const { restaurant } = useRestaurant();
    const {
      siteRestaurant,
      error,
      loading
    } = useRestaurantSiteData({
      staticContext,
      domainQueryHook: useSiteContentByDomainQuery,
      shortUrlQueryHook: useSiteContentByShortUrlQuery,
      mgmtGuidQueryHook: useSiteContentByMgmtGuidQuery
    });

    const backgroundColorStyle = useMemo(() => {
      return config?.backgroundColor
        ? { backgroundColor: config?.backgroundColor }
        : {};
    }, [config?.backgroundColor]);

    const { meta, theme } = restaurant;

    if(error) {
      return <UhOh meta={meta} siteTheme={theme} />;
    }

    if(loading) {
      return null;
    }

    if(!content || !editPathRoot) {
      return <NoMatch404 meta={meta} siteTheme={theme} />;
    }

    let { primaryCta = null, nonPrimaryCtas = null } = siteRestaurant.content as SiteContent;

    return (
      <div className="defaultTemplate" data-testid="dynamic-menu-page" style={backgroundColorStyle} id="main">
        <Nav
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          navType="normal"
          primaryCta={primaryCta}
          nonPrimaryCtas={nonPrimaryCtas}
          shouldShowPreviewBanner={true} />
        <DynamicMenuPage content={content} editPathRoot={editPathRoot} config={config} />
        <Footer />
      </div>
    );
  }
);

export default withRouter<RequestContextProps & DynamicMenuPageProps, React.ComponentType<RequestContextProps & DynamicMenuPageProps>>(WrappedDynamicMenuPage);
