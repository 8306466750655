import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import queryString from 'query-string';

import { toBase64 } from 'src/lib/js/base64';
import { useQueryString } from 'src/lib/js/hooks/useQueryString';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { ToggleInput } from 'src/shared/components/common/forms';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import Footer from 'public/components/toast_local/common/Footer';
import CuisineFilters from 'public/components/toast_local/common/cuisine_filters/CuisineFilters';
import LocationDropDown from 'public/components/toast_local/common/location_dropdown/LocationDropdown';
import Nav from 'public/components/toast_local/common/nav/Nav';
import SearchBar from 'public/components/toast_local/common/search_bar/SearchBar';
import PaginatedRXs from 'public/components/toast_local/paginated_rxs/PaginatedRXs';

import { useShowAcceptsToastCashFilter } from './toast-cash-accepted-helpers';
import { geoFilterSet } from './trackingUtils';

const RxSearch = ({ mapView }: { mapView?: boolean }) => {
  const parsedQS = useQueryString(['filters'], ['filters']);
  const history = useHistory();
  const [resetString, setResetString] = useState(false);
  const searchPageLandingViewedRef = useRef(false);
  const { loadingCustomer } = useCustomer();
  const { track } = useThrottledTracker();

  // Only track on initial landing experience (ampltiude budget control) via ref.
  useEffect(() => {
    if(typeof window !== 'undefined' && !searchPageLandingViewedRef.current && !loadingCustomer) {
      track('Local search view', {
        cuisine: parsedQS?.filters?.cuisine || null,
        geoFilter: geoFilterSet(parsedQS?.filters),
        search: !!parsedQS?.filters?.search
      });
      searchPageLandingViewedRef.current = true;
    }
  }, [parsedQS?.filters, track, loadingCustomer]);

  const onClickCuisineFilter = (event: React.MouseEvent, cuisine: string) => {
    // The default behavior of cuisine filters is to link to another page. This prevents that.
    event.preventDefault();
    if(parsedQS.filters && parsedQS.filters.cuisine === cuisine) {
      history.push(`/local/search?${queryString.stringify({
        ...parsedQS,
        filters: toBase64(JSON.stringify({ ...parsedQS.filters, cuisine: null }))
      })}`);
    } else {
      history.push(`/local/search?${queryString.stringify({
        ...parsedQS,
        filters: toBase64(JSON.stringify({ ...parsedQS.filters, cuisine }))
      })}`);
    }
  };

  const onLocationChange = (street: string, neighborhood: string, city: string, state: string, lat: number, long: number) => {
    history.push(`/local/search?${queryString.stringify({
      ...parsedQS,
      filters: toBase64(JSON.stringify({
        ...parsedQS.filters,
        userStreet: street || '',
        userNeighborhood: neighborhood || '',
        userCity: city,
        userState: state,
        userLat: lat,
        userLong: long
      }))
    })}`);
  };

  const locationTitle = <LocationDropDown
    label="Restaurants near"
    parsedQS={parsedQS}
    street={parsedQS.filters && parsedQS.filters.userStreet}
    neighborhood={parsedQS.filters && parsedQS.filters.userNeighborhood}
    city={parsedQS.filters?.userCity}
    state={parsedQS.filters?.userState}
    onLocationSelected={onLocationChange} />;

  const showAcceptsToastCashFilterToggle = useShowAcceptsToastCashFilter();

  return (
    <div className="defaultTemplate">
      <Nav />
      <div className="rx-search container">
        <Helmet>
          {/* Prevent crawlers from making expensive searches that slow our service. */}
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <CuisineFilters title={locationTitle} citySubPath="" selectedCuisine={parsedQS.filters && parsedQS.filters.cuisine} onClickFilter={onClickCuisineFilter} />
        <div className="rx-filters">
          <div className="rx-filter">
            <SearchBar placeholder="Restaurant, cuisine, or dish" className="small" resetString={resetString} onStringReset={() => setResetString(false)} />
          </div>
          {/* Force presence based upon presence in QS */}
          {showAcceptsToastCashFilterToggle
            ?
            <div
              className="accepts-toast-cash-toggle">
              <ToggleInput
                onChange={e => {
                  history.push(`/local/search?${queryString.stringify({
                    ...parsedQS,
                    filters: toBase64(JSON.stringify({ ...parsedQS.filters, acceptsToastCash: e.target.checked }))
                  })}`);
                }}
                id="acceptsToastCashCheckbox"
                name="acceptsToastCash"
                checked={parsedQS.filters.acceptsToastCash ?? false}
                type="checkbox"
                ariaLabel="Accepts Toast Cash">
                Accepts Toast Cash
              </ToggleInput>
            </div>

            : null}
        </div>
        <PaginatedRXs utmContent="search_oo" mapView={mapView} />
      </div>
      <Footer />
    </div>
  );
};

export default RxSearch;
