import React, { useCallback, useState, useRef, useEffect } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { ButtonType } from 'src/apollo/sites';
import useScrollDirection from 'src/lib/js/hooks/useScrollDirection';
import { formatImageURL } from 'src/lib/js/utilities';
import { SitesToastContainer } from 'src/shared/js/alertUtils';

import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import Button from 'shared/components/common/button';
import { ModalCloseButton, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import PrimaryCTA, { PrimaryCTAClasses } from 'shared/components/primary_cta/PrimaryCTA';

import { CTAData, getCtaContent } from 'public/components/default_template/ctas';
import { CTAItemModal } from 'public/components/default_template/ctas/CTAItem';
import { ReservationModalContent } from 'public/components/default_template/nav/NavModalContents';
import SecondaryModal from 'public/components/default_template/reservation/SecondaryModal';
import { Customer, useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import NavContent, { CommonNavProps } from './NavContent';
import { MobileNavItems } from './NavItems';
import { MobileUserNavItems } from './UserNav';
import { getNavColorStyles, getOverlayStyles } from './navUtils';

type Props = CommonNavProps & {
  orderingUrl?: string | null;
  reservationsUrl?: string | null;
  primaryCta?: CTAData | null;
  customer?: Customer;
};

const Nav = (props: React.PropsWithChildren<Props>) => {
  const { isEditor } = useEditor();
  const { isOpen, onClose, onOpen } = useModal();
  const { isOpen: secondaryIsOpen, onClose: secondaryOnClose, onOpen: secondaryOnOpen } = useModal();
  const { restaurant, ooRestaurant, orderingUrl, reservationsUrl, phoneNumber, locations, updateLocation } = useRestaurant();
  const { homePath } = useRestaurantRoutes();
  const [secondaryModalContent, setSecondaryModalContent] = useState<JSX.Element>();
  const modalNavContainer = useRef<HTMLDivElement | null>(null);
  const showLocationNames: boolean = !!restaurant?.config.showLocationNames;

  const ctaProps = {
    restaurantConfig: restaurant.config,
    orderingUrl,
    reservationsUrl,
    phoneNumber,
    updateLocation,
    locations,
    reservationOnOpen: () => {
      setSecondaryModalContent(<ReservationModalContent />);
      secondaryOnOpen();
    },
    giftcardsUrl: ooRestaurant?.giftCardLinks?.purchaseLink
  };

  const onSecondaryModalClose = () => {
    setSecondaryModalContent(undefined);
    secondaryOnClose();
  };


  const { scrollDirection } = useScrollDirection();

  const [displayingOverlay, setDisplayingOverlay] = useState(false);

  const primaryCtaContent = props.primaryCta ? getCtaContent(props.primaryCta, showLocationNames, ctaProps) : null;

  useEffect(() => {
    // if overlay is displayed, set the focus to it.  this allows for accessible keyboard navigation
    if(displayingOverlay) modalNavContainer.current?.focus();
  }, [displayingOverlay]);

  const itemModalOnClose = useCallback(() => {
    // in order to keep the item modal rendered, the mobile overlay
    // needs to be left open until the item modal is closed
    onClose();
    setDisplayingOverlay(false);
  }, [onClose, setDisplayingOverlay]);

  const modalContentStyle = getNavColorStyles(restaurant, false);
  const modalOverlayStyle = getOverlayStyles();

  // If we don't have a logo for some reason, try falling back to the BFF's logo
  const logoSrc = props.logoSrc || ooRestaurant?.logoUrls?.small && formatImageURL(ooRestaurant.logoUrls.small) || '';
  const mobileOverlay = isOpen
    ?
    <CTAItemModal cta={primaryCtaContent} isOpen={isOpen} onClose={itemModalOnClose} />
    :
    <div className="mobileNavOverlay" data-testid="hamburger-modal" style={modalOverlayStyle} ref={modalNavContainer} tabIndex={-1}>
      <div className="overlay" onClick={() => setDisplayingOverlay(false)} />
      <div className="content" style={modalContentStyle} >
        <div className="header">
          <ModalCloseButton onClose={() => setDisplayingOverlay(false)} />
        </div>
        <div className="body">
          <div className="navLinks">
            <MobileNavItems ctas={props.nonPrimaryCtas} ctaProps={ctaProps} closeOverlay={() => setDisplayingOverlay(false)} color={modalContentStyle.color} />
          </div>
        </div>
        <div className="footer">
          {primaryCtaContent
            ?
            <>
              {primaryCtaContent.submenus?.length
                ?
                <Button variant={ButtonType.Primary} className={classnames('cta', PrimaryCTAClasses)} onClick={onOpen}>
                  {primaryCtaContent.text}
                </Button>
                :
                <div className="cta">
                  <AttributionContextProvider utm_campaign={primaryCtaContent.utm_campaign}>
                    <PrimaryCTA href={primaryCtaContent.link} onClick={() => setDisplayingOverlay(false)} text={primaryCtaContent.text} />
                  </AttributionContextProvider>
                </div>}
              <hr />
            </>
            : null}
          <div className="navLinks">
            <MobileUserNavItems
              onOpen={secondaryOnOpen}
              onClose={secondaryOnClose}
              setModalContent={setSecondaryModalContent}
              closeOverlay={() => setDisplayingOverlay(false)}
              color={modalContentStyle.color} />
          </div>
        </div>
      </div>
    </div>;
  return (
    <>
      <SitesToastContainer />
      <SitesToastContainer containerId={'overModals'} style={{ zIndex: 1000 }} />
      {!isEditor && <SecondaryModal isOpen={secondaryIsOpen} onClose={onSecondaryModalClose} modalContent={secondaryModalContent} />}
      {displayingOverlay ? mobileOverlay : null}
      <NavContent
        {...props}
        restaurant={restaurant}
        logoLink={homePath}
        logoSrc={logoSrc}
        logoObject={props.logoObject}
        scrollDirection={scrollDirection}
        ctaProps={ctaProps}
        primaryCtaContent={primaryCtaContent}
        setDisplayingOverlay={setDisplayingOverlay}
        shouldShowPreviewBanner={props.shouldShowPreviewBanner} />
    </>
  );
};

const NavWithCustomer = (props: React.PropsWithChildren<Props>) => {
  const { customer } = useCustomer();
  return <Nav {...props} customer={customer} />;
};

const WrappedNav = (props: React.PropsWithChildren<Props>) => {
  const { isEditor } = useEditor();
  return isEditor ? <Nav {...props} /> : <NavWithCustomer {...props} />;
};

export default WrappedNav;
