import React, { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { ContainsEditableProps, useEditor, FieldType } from '@toasttab/sites-components';

import { Block as BlockType } from 'src/apollo/sites';

import EditableIFramedCode from 'shared/components/common/embedded_code/EditableIFramedCode';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { server } from 'config';

export const EmptyEmbeddedCodeSection = () =>
  <div className="embeddedCode empty">Nothing here yet</div>;

type ElementDims = {
  height: number;
  width: number;
};

const EmbeddedCode = React.forwardRef((
  { block }: { block?: BlockType; },
  ref: React.RefObject<HTMLDivElement>
) => {
  const [dims, setDims] = useState<ElementDims | null>();
  const defaultRef = useRef<HTMLDivElement>(null);
  const divRef = ref || defaultRef;

  const resizeIFrame = useCallback(() => {
    const wrapper = divRef.current?.closest('.blockWrapper');
    if(wrapper) {
      setDims({ height: wrapper.clientHeight, width: wrapper.clientWidth });
    }
  }, [divRef]);

  return (
    <div ref={divRef}>
      {block?.contents?.code ?
        <EditableIFramedCode
          onIframeChange={resizeIFrame}
          code={block?.contents?.code}
          parentHeight={dims?.height}
          parentWidth={dims?.width} /> :
        <EmptyEmbeddedCodeSection />}
    </div>
  );
});
EmbeddedCode.displayName = 'EmbeddedCode';

type EditableProps = { block?: BlockType; } & ContainsEditableProps;

const EditableEmbeddedCode = (props: EditableProps) => {
  const { editPath, block } = props;
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'embedded code',
    displayName: 'Embedded HTML',
    actions: ['delete'],
    path: editPath,
    schema: {
      fields: [
        {
          displayName: 'Add your code here (HTTPS only)',
          path: `${editPath}.code`,
          type: FieldType.TextArea,
          value: block?.contents?.code ?? '',
          helperText: 'Make sure that your code contains HTTPS not HTTP or it will not be displayed on your live site.'
        },
        {
          displayName: 'What\'s in the embedded HTML?',
          path: `${editPath}.altText`,
          type: FieldType.Text,
          value: block?.contents?.altText ?? '',
          validation: { maxLen: 230 },
          helperText: 'Optional. Additional information you want to highlight to guests. Max 230 characters.'
        }
      ]
    }
  });

  return <EmbeddedCode ref={editableRef} block={block} />;
};

const EmbeddedCodeIFrame = ({ block, isPopup }: { block: BlockType, isPopup?: boolean }) => {
  const { pathname } = useLocation();
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null);
  const { selectedLocation } = useRestaurant();
  const height = ref?.parentElement?.clientHeight;
  const width = ref?.parentElement?.clientWidth;

  return (
    <iframe
      ref={setRef}
      title={block.contents.altText || 'Embedded code'}
      className="embeddedCode"
      height={height}
      width={width}
      src={
        `
          ${server.protocol}://${server.fullHost}/sites-web/v1/iframe?
            blockGuid=${block.guid}
            &shortUrl=${selectedLocation.shortUrl}
            &height=${height}
            &width=${width}
            &path=${pathname}
            &popup=${isPopup ?? false}
        `.replace(/\s/g, '')
      }>
    </iframe>
  );
};

// EmbeddedCode blocks within a popup are editable via the popup content form in the editor
const WrappedEmbeddedCode = ({ block, editPath, isPopup }: { block: BlockType, editPath: string, isPopup?: boolean }) => {
  const { isEditor } = useEditor();
  return isEditor ?
    isPopup ?
      <EmbeddedCode block={block} /> :
      <EditableEmbeddedCode editPath={editPath} block={block} /> :
    <EmbeddedCodeIFrame block={block} isPopup={isPopup} />;
};

export default WrappedEmbeddedCode;
