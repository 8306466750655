import React, { useEffect, useMemo, useState } from 'react';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { GuestCheckoutButton, ToastCheckoutButton } from 'public/components/default_template/online_ordering/checkout/CheckoutModeButtons';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import FulfillmentSection from 'public/components/default_template/online_ordering/checkout/FulfillmentSection';
import OrderSection from 'public/components/default_template/online_ordering/checkout/OrderSection';
import OrderPrices from 'public/components/default_template/online_ordering/checkout/payment/OrderPrices';
import PaymentSection from 'public/components/default_template/online_ordering/checkout/payment/PaymentSection';
import { AcceptedPaymentMethods } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import CheckoutNote, { UberCheckoutNote } from './CheckoutNote';
import SubmitOrderButton from './SubmitOrderButton';
import ContinuePaymentButton from './international/ContinuePaymentButton';
import InternationalPaymentContainer from './international/InternationalPaymentContainer';

const CheckoutSections = () => {
  const { customer } = useCustomer();
  const { cart } = useCart();
  const { orderTotal, orderError } = useCheckout();
  const { paymentOption } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant();
  const [selectedCheckoutMode, setSelectedCheckoutMode] = useState(false);
  const [showIntlPayment, setShowIntlPayment] = useState<boolean>(false);
  const canPayAtCheckout = !(cart?.paymentOptions.atCheckout.length === 0 && cart?.paymentOptions.uponReceipt.length > 0);
  const showUSPayments = useMemo(() => (selectedCheckoutMode || Boolean(customer)) && !showIntlPayment, [customer, selectedCheckoutMode, showIntlPayment]);

  useEffect(() => {
    if(isIntlRestaurant && orderError) {
      setShowIntlPayment(false);
    }
  }, [isIntlRestaurant, orderError]);

  return (
    <div className="checkoutFormContents" role="form">
      <FulfillmentSection complete={selectedCheckoutMode} />
      <OrderSection />
      {!selectedCheckoutMode && !customer &&
          <>
            <OrderPrices />
            <div className="checkoutModeButtonGroup">
              <ShowForUS>
                <ToastCheckoutButton />
              </ShowForUS>
              <GuestCheckoutButton onClick={() => setSelectedCheckoutMode(true)} />
            </div>
            {canPayAtCheckout &&
              <CheckoutSection>
                <span>We accept</span>
                <AcceptedPaymentMethods />
              </CheckoutSection>}
          </>}
      <AnimatedSection expanded={showUSPayments} slowTransition testid="animated-payment-section" >
        <div className="checkoutFormContents" data-testid="checkoutFormInfo">
          <PaymentSection isExpanded={showUSPayments} />
          <CheckoutSection>
            <div className="sectionButton">
              {isIntlRestaurant && orderTotal > 0 && canPayAtCheckout && paymentOption === PaymentOption.CreditCard ?
                <ContinuePaymentButton enableIntlPayment={() => setShowIntlPayment(true)} /> :
                <SubmitOrderButton />}
              <CheckoutNote showIntlPayment={showIntlPayment} paymentOption={paymentOption} />
            </div>
          </CheckoutSection>
        </div>
      </AnimatedSection>
      {isIntlRestaurant && <InternationalPaymentContainer expanded={showIntlPayment} hideIntlPayment={() => setShowIntlPayment(false)} />}
      <UberCheckoutNote selectedCheckoutMode={selectedCheckoutMode} />
    </div>
  );
};

export default CheckoutSections;
