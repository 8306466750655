import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { DeliveryInfoInput, SavedAddress, useUpdateAddressMutation } from 'src/apollo/onlineOrdering';
import InputField from 'src/shared/components/common/form_input/InputField';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import Button from 'shared/components/common/button';
import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'shared/components/common/location_search/PlacesAutocomplete';
import { alertError, alertSuccess } from 'shared/js/alertUtils';

import { useStaticSiteContext } from 'public/components/app/StaticSiteContext';
import { normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';
import { isToastLocalRequest } from 'public/js/siteUtilities';

import { gPlacesAPIKey } from 'config';


interface EditAddressProps {
  address: SavedAddress
  onSuccessfulUpdate: () => void;
  selectedLocationLat?: number;
  selelctedLocationLong?: number;
}

type EditAddressFormInputs = {
  label: string;
  addressLine1: string;
  addressLine2: string;
  deliveryInstructions: string;
  deliveryZipCode: string;
}

export const EditAddress: FC<EditAddressProps> = ({ address, onSuccessfulUpdate, selectedLocationLat, selelctedLocationLong }) => {
  const [UpdateAddressMutation] = useUpdateAddressMutation();
  const ooClient = useOOClient();
  const staticContext = useStaticSiteContext();
  const isToastLocal = isToastLocalRequest(staticContext);
  const [selectedAddress, setSelectedAddress] = useState<DeliveryInfoInput|null>();
  const formMethods = useForm<EditAddressFormInputs>({
    mode: 'onTouched', defaultValues: {
      label: address.name || '',
      addressLine1: address.deliveryInfo.address1,
      addressLine2: address.deliveryInfo.address2 || '',
      deliveryInstructions: address.deliveryInfo.notes || '',
      deliveryZipCode: address.deliveryInfo.zipCode
    }
  });
  formMethods.register('addressLine1', { required: true });
  const label = useWatch({ name: 'label', control: formMethods.control });
  const addressLine2 = useWatch({ name: 'addressLine2', control: formMethods.control });
  const deliveryInstructions = useWatch({ name: 'deliveryInstructions', control: formMethods.control });
  const deliveryZipCode = useWatch({ name: 'deliveryZipCode', control: formMethods.control });
  const { setValue } = formMethods;
  const setAddressLines = useCallback((line1: string, line2: string) => {
    setValue('addressLine1', line1, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('addressLine2', line2, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  }, [setValue]);

  const onPlaceSelected = useCallback(async (place: google.maps.places.PlaceResult) => {
    const normalizedAddress = normalizeGoogleAddress(place);
    setAddressLines(normalizedAddress?.address1 || '', normalizedAddress?.address2 || '');
    setSelectedAddress(normalizedAddress);
  }, [setAddressLines]);


  const onSubmit = useCallback(async () => {
    try {
      const newDeliveryInfo = selectedAddress ? selectedAddress : address.deliveryInfo;
      const { data } = await UpdateAddressMutation({
        variables: {
          input: {
            name: label,
            deliveryInfo: {
              address1: newDeliveryInfo.address1,
              address2: addressLine2,
              city: newDeliveryInfo.city,
              state: newDeliveryInfo.state,
              zipCode: deliveryZipCode || newDeliveryInfo.zipCode,
              latitude: newDeliveryInfo.latitude,
              longitude: newDeliveryInfo.longitude,
              notes: deliveryInstructions,
              administrativeArea: newDeliveryInfo.administrativeArea,
              country: newDeliveryInfo.country
            },
            guid: address.guid
          }
        },
        client: ooClient
      });
      if(!data || data.updateAddress.__typename === 'UpdateAddressError') {
        alertError('Something went wrong, please try again later');
      } else if(data.updateAddress.__typename === 'UpdateAddressResponse') {
        alertSuccess('Address updated successfully');
        onSuccessfulUpdate();
      }
    } catch(e) {
      alertError('Something went wrong, please try again later');
      reportError(e);
    }
  }, [UpdateAddressMutation, address, addressLine2, deliveryInstructions, deliveryZipCode, label, onSuccessfulUpdate, selectedAddress, ooClient]);

  return (
    <FormProvider {...formMethods}>
      <div className="editAddressWrapper">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="editAddressInputs">
            <InputField id="label" type="text" label="Address Label" required />
            <InputField id="addressLine1" type="text" label="Address" required >
              <PlacesAutocomplete
                id="edit-address-input-auto-complete"
                defaultValue={`${address.deliveryInfo.address1}, ${address.deliveryInfo.city}, ${address.deliveryInfo.state} ${address.deliveryInfo.zipCode}`}
                placeholder="Enter delivery address"
                apiKey={gPlacesAPIKey}
                placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
                onPlaceSelected={onPlaceSelected}
                locationBias={selectedLocationLat && selelctedLocationLong ? { lat: selectedLocationLat, long: selelctedLocationLong } : undefined}
                options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }}
                normalized={true}
                showGenericSpinner={isToastLocal}
                onValueChange={value => setAddressLines(value, addressLine2)} />
            </InputField>
            <InputField id="addressLine2" type="text" label="Apt./Suite no." />
            <InputField id="deliveryInstructions" type="text" label="Delivery Instructions" />
          </div>

          <div className="actionsWrapper">
            <Button className="cancelAddressEditBtn"
              onClick={onSuccessfulUpdate}>
              Cancel
            </Button>
            <Button className="updateAddressBtn" type="submit"
              disabled={!(formMethods.formState.isValid && formMethods.formState.isDirty) || formMethods.formState.isSubmitting}>
              Update my address
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

