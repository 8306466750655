import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { createClient } from 'src/apollo/createClient';
import { PaddingEnum, PopupButtonActionType, PopupLayout, useSubmitFormPopupMutation, Block as BlockType } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import InputField from 'src/shared/components/common/form_input/InputField';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { EmptyEmbeddedCodeSection } from 'shared/components/common/embedded_code/EmbeddedCode';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { DynamicSection } from 'public/components/default_template/dynamic_section/DynamicSection';
import { GRID_GAP } from 'public/components/default_template/dynamic_section/DynamicSectionUtils';
import PromoCode from 'public/components/default_template/popups/PromoCode';
import { LoyaltySignupPopup } from 'public/components/default_template/popups/loyalty_signup/LoyaltySignupPopup';

import { resources } from 'config';

import { getHasShown, setHasShown, toRichTextField } from './utils';

type formData = { [key: string]: string };

type Props = {
  isOrderPage?: boolean;
}

type WrappedProps = {
  route: string
} & Props

// 50px per cell, height and width
const DYNAMIC_SECTION_CELL_SIZE = 50;

const DynamicSectionPopup = ({ popupIndex, dynamicBlock }: { popupIndex?: number, dynamicBlock: BlockType }) => {
  const { isEditor } = useEditor();

  // subtract 1 so that the end of the block and the end of the section align
  const numCols = dynamicBlock.endX - 1;
  // subtract 1 to match the columns
  const numRows = dynamicBlock.endY - 1;

  // 48px from modalWrapper padding
  // 24px from popup padding
  // 50px from popupHeader height
  const maxModalContentWidth = `calc((100% ${isEditor ? '- (24px * 2) ' : ''}- (${numCols} * ${GRID_GAP}px)) / ${numCols}`;
  const maxModalContentHeight = `calc((100vh - (48px * 2) - 24px - 50px - (${numRows} * ${GRID_GAP}px)) / ${numRows}`;
  return (
    <DynamicSection
      isPopup
      blocks={[dynamicBlock]}
      numCols={numCols}
      mobileNumCols={(dynamicBlock.mobileEndX ?? dynamicBlock.endX) - 1}
      numRows={numRows}
      mobileNumRows={(dynamicBlock.mobileEndY ?? dynamicBlock.endY) - 1}
      editPath={`content.popups[${popupIndex}].dynamicSection`}
      padding={PaddingEnum.None}
      cellWidthCSS={`min(${DYNAMIC_SECTION_CELL_SIZE}px, ${maxModalContentWidth})`}
      cellHeightCSS={`min(${DYNAMIC_SECTION_CELL_SIZE}px, ${maxModalContentHeight})`} />
  );
};

const WrappedPopups = ({ route, isOrderPage }: WrappedProps) => {
  const { isEditor, popupGuid, useEditableRef } = useEditor();
  const { restaurant } = useRestaurant();
  const { isOpen, onClose, onOpen } = useModal();
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const formMethods = useForm<formData>();
  const { handleSubmit } = formMethods;
  const [submitFormPopupMutation] = useSubmitFormPopupMutation();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Find the most applicable popup for the current page
  // 1. Is enabled
  // 2. Can be shown on the current page
  // Unless we're in the editor and we're viewing a specific popup
  const popupIndex = useMemo(() => restaurant.content?.popups?.findIndex(popup => {
    if(isEditor && popupGuid) {
      return popup.guid === popupGuid;
    }

    // If popup.applicablePages is null, it should be shown on all pages, if it is the empty list it should not be shown on any pages.
    return popup.enabled &&
      (!popup.applicablePages || popup.applicablePages.includes(route) ||
      isOrderPage && popup.applicablePages.includes('/order'));
  }), [restaurant, route, isEditor, popupGuid, isOrderPage]);
  const popup = typeof popupIndex !== 'undefined' && popupIndex >= 0 ? restaurant.content?.popups?.[popupIndex] : undefined;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'popup',
    path: `content.popups[${popupIndex}]`,
    actions: ['delete'],
    schema: { fields: [] }
  });

  const onSubmit = useCallback((data: formData) => {
    if(!popup) {
      // this should never happen
      return;
    }

    setSubmitting(true);
    try {
      submitFormPopupMutation({
        variables: {
          formId: popup.guid,
          data: data
        }
      });
      setSubmitted(true);
    } catch(e) {
      reportError('Failed to submit popup form', e);
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        onClose();
      }, (popup?.form?.successTimeout || 0) * 1000);
    }
  }, [onClose, popup, submitFormPopupMutation]);

  useEffect(() => {
    if(!isEditor && typeof window !== 'undefined' && popup?.enabled && !getHasShown(popup.guid)) {
      setTimeout(() => {
        onOpen();
        setHasShown(popup.guid);
      }, (popup?.displayDelay || 0) * 1000);
    }
  }, [popup, onOpen, onClose, isEditor]);

  // dont display EmbeddedCode popups without any content, unless we're in the editor
  const dynamicBlock = popup?.dynamicSection?.blocks?.[0];
  if(!popup || !isEditor && popup.layout === PopupLayout.EmbeddedCode && !dynamicBlock) {
    return null;
  }

  const header = toRichTextField(popup.header);
  const description = toRichTextField(popup.description);
  const isShelf = popup.layout === PopupLayout.FormSlideIn;

  // The width of a mobile popup is fixed
  let width = undefined;
  if(!isMobile && !!dynamicBlock) {
    const numCols = dynamicBlock.endX - dynamicBlock.startX + 1;
    width = numCols * DYNAMIC_SECTION_CELL_SIZE + (numCols - 1) * GRID_GAP;
  }

  const contents =
    <>
      <div className="popupHeader">
        {popup.dismissible !== false && <ModalCloseButton />}
      </div>
      <div className="popup" style={{ backgroundColor: popup.backgroundColor || undefined, width, maxWidth: dynamicBlock ? '100%' : undefined }}>
        {popup.layout === PopupLayout.LoyaltySignup
          ?
          <LoyaltySignupPopup
            header={header.text} headerColor={header.color} subheaderColor={description.color}
            onClose={onClose} successTimeout={(popup?.form?.successTimeout || 3) * 1000} />
          :
          popup.layout === PopupLayout.EmbeddedCode ?
            dynamicBlock ?
              <DynamicSectionPopup popupIndex={popupIndex} dynamicBlock={dynamicBlock} /> :
              <EmptyEmbeddedCodeSection />
            :
            <>
              <div className="popupContent">
                {popup.promoCodeGuid ?
                  <div style={{ color: description.color, fontFamily: description.fontFamily }}>
                    <PromoCode promoCodeGuid={popup.promoCodeGuid} fontFamily={description.fontFamily} />
                  </div>
                  : <h2 id="popup-heading" style={{ color: header.color, fontFamily: header.fontFamily }}>{header.text}</h2>}
                <div className="popupBody">
                  {(popup.layout === PopupLayout.ImageAndText || popup.layout === PopupLayout.FormTextAndImage || popup.layout === PopupLayout.FormSlideIn) && popup.image ?
                    <Image className="image" src={popup.image} alt="" />
                    : null}
                  <p style={{ color: description.color, fontFamily: description.fontFamily }}>{description.text}</p>
                </div>
                {submitted ? <h2>{popup.form?.successMessage}</h2> : null}
                {!submitted && (popup.layout === PopupLayout.FormTextAndImage || popup.layout === PopupLayout.FormTextOnly || popup.layout === PopupLayout.FormSlideIn) && popup.form ?
                  <div className="popupForm">
                    <FormProvider {...formMethods}>
                      <form>
                        <InputField id={popup.form.formLabel} label={popup.form.formLabel} required />
                        {popup.layout === PopupLayout.FormTextOnly && popup.form.secondaryFormLabel ?
                          <InputField required id={popup.form.secondaryFormLabel} label={popup.form.secondaryFormLabel} />
                          : null}
                      </form>
                    </FormProvider>
                  </div>
                  : null}
              </div>
              <div className="popupActions">
                {popup.buttons?.map((btn, i) => {
                  if(!btn) return null;
                  return (
                    <Button
                      key={`modal-action-${i}`}
                      disabled={submitting || submitted}
                      onClick={() => {
                        switch(btn.action?.action) {
                          case PopupButtonActionType.Link:
                            if(btn.action?.metadata) {
                              window.open(btn.action?.metadata, '_blank', 'noopener noreferrer');
                            }
                            onClose();
                            break;
                          case PopupButtonActionType.Submit:
                            if(popup.form) {
                              handleSubmit(onSubmit)();
                            } else {
                              onClose();
                            }
                            break;
                          case PopupButtonActionType.Close:
                          default:
                            onClose();
                            break;
                        }
                      }}
                      variant={btn.buttonType}>
                      {btn.text}
                    </Button>
                  );
                })}
                {!popup.buttons?.length && popup.button ?
                  <Button disabled={submitting || submitted} onClick={() => {
                    if(popup.form) {
                      handleSubmit(onSubmit)();
                    } else {
                      onClose();
                    }
                  }} variant={popup.button.buttonType}>
                    {popup.button?.text}
                  </Button>
                  : null}
              </div>
            </>}
      </div>
    </>;

  if(isEditor) {
    return (
      <div className="editorPopup" data-testid="popup-modal-editor">
        <div className="editorPopupOverlay" style={{ opacity: popup.overlayOpacityPercentage ? popup.overlayOpacityPercentage / 100 : undefined, backgroundColor: popup.overlayColor || undefined }} />
        <div className={classnames('editorPopupWrapper', popup.placement, { popupShelf: isShelf })}>
          <div className="editorPopupContents" style={{ backgroundColor: popup.backgroundColor || undefined }} ref={editableRef}>
            {contents}
          </div>
        </div>
      </div>
    );
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose} testId="popup-modal" preventOverlayClose={popup.dismissible === false}>
      <ModalOverlay color={popup.overlayColor || undefined} opacity={popup.overlayOpacityPercentage || undefined} fadeIn={isShelf} fadeOut={isShelf} />
      <ModalContent style={{ backgroundColor: popup.backgroundColor || undefined }} ariaLabelledBy={'popup-heading'}
        wrapperClassName={classnames('popupWrapper', popup.placement, { popupShelf: isShelf })} contentClassName="content" slideIn={isShelf} slideOut={isShelf}>
        {contents}
      </ModalContent>
    </Modal>
  );
};

const EditorPopups = () => {
  const { url, popupMode } = useEditor();

  if(!popupMode) {
    return null;
  }

  return <WrappedPopups route={url} />;
};

const SitesPopups = (props: Props) => {
  const { pathname } = useLocation();

  return (
    <ApolloProvider client={createClient(resources.apiEndpoint, undefined, false, { enabled: true }, undefined, false, false, resources.clientQueryTimeoutMs)}>
      <WrappedPopups route={pathname} isOrderPage={props.isOrderPage} />
    </ApolloProvider>
  );
};

const Popups = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor ? <EditorPopups /> : <SitesPopups {...props} />;
};

export default Popups;
