import React from 'react';

import cx from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';
import { ShowForUS } from 'shared/components/common/show_for_us/ShowForUS';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import { useSpi } from './useSpi';

/** Set of radio buttons for selecting payment options (e.g. Pay now/later, credit/debit/ApplePay) */
const PaymentOptionsSection = () => {
  const { spiEnabled } = useSpi();
  const { enabledPaymentOptions } = useCheckout();
  const { cart } = useCart();
  const { paymentOption, setPaymentOption } = usePayment();

  const canPayLater = enabledPaymentOptions.has(PaymentOption.UponReceipt);
  const canPayNow = enabledPaymentOptions.has(PaymentOption.CreditCard) || enabledPaymentOptions.has(PaymentOption.PayNow);

  if(!canPayLater && !canPayNow || !cart) {
    return null;
  }

  if(!canPayNow) {
    const message =
      cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
        'Please pay delivery driver upon receiving your order.' :
        'Please pay at the restaurant when you pick up your order.';
    return <div className="payAtRestaurantNote">{message}</div>;
  }

  if(spiEnabled) {
    return canPayLater ?
      <div className="paymentTabs" role="tablist">
        <button
          type="button"
          role="tab"
          aria-selected={paymentOption !== PaymentOption.UponReceipt}
          className={cx({ selected: paymentOption !== PaymentOption.UponReceipt })}
          onClick={() => setPaymentOption(PaymentOption.PayNow)}>
          Pay now
        </button>
        <button
          type="button"
          role="tab"
          aria-selected={paymentOption === PaymentOption.UponReceipt}
          className={cx({ selected: paymentOption === PaymentOption.UponReceipt })}
          onClick={() => setPaymentOption(PaymentOption.UponReceipt)}>
          {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
        </button>
      </div>
      : null;
  }

  const showPaymentTypeButtons = enabledPaymentOptions.size > 1 && paymentOption !== PaymentOption.UponReceipt;

  return (
    <div data-testid="payment-option-radio-buttons">
      {canPayLater &&
        <div className="paymentButtons">
          <ToggleInput
            checked={paymentOption !== PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.CreditCard)}
            name="payNow"
            id="payNow"
            type="radio"
            dataTestId="input-payNow">
            Pay now
          </ToggleInput>
          <ToggleInput
            checked={paymentOption === PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.UponReceipt)}
            name="payLater"
            id="payLater"
            type="radio"
            dataTestId="input-payLater">
            {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
          </ToggleInput>
        </div>}
      <ShowForUS>
        <AnimatedSection expanded={showPaymentTypeButtons} testid="pay-now-options-animated-section">
          <hr />
          <div className="paymentButtons">
            {enabledPaymentOptions.has(PaymentOption.ApplePay) &&
            <ToggleInput
              checked={paymentOption === PaymentOption.ApplePay}
              onChange={() => setPaymentOption(PaymentOption.ApplePay)}
              name="applepay-checkoutMethod"
              id="applyPay"
              type="radio"
              dataTestId="input-applePay">
              <Image className="appleLogo" alt="ApplePay" src="/icons/apple-pay.svg" />
            </ToggleInput>}
            <ToggleInput
              checked={paymentOption === PaymentOption.CreditCard}
              onChange={() => setPaymentOption(PaymentOption.CreditCard)}
              name="card-checkoutMethod"
              id="payWithCard"
              type="radio"
              dataTestId="input-cc">
                Debit or credit card
            </ToggleInput>
          </div>
        </AnimatedSection>
      </ShowForUS>
    </div>
  );
};

export default PaymentOptionsSection;
