import { useEffect, useRef } from 'react';

import { isEqual } from 'lodash';

import { MenuTemplate } from 'src/apollo/sites';
import { formatPrice } from 'src/lib/js/utilities';

import { UpsellItem } from 'public/components/default_template/online_ordering/upsell/UpsellItem';


export const allHaveImage = (items: UpsellItem[], template: MenuTemplate) => {
  const imageTemplates = [MenuTemplate.Classic, MenuTemplate.LeftImageV2, MenuTemplate.TopImageV2];
  return imageTemplates.includes(template) && items.filter(item => !!item?.imageUrls).length === items.length;
};

export const getUpsellItemPrice = (item: UpsellItem) => {
  const defaultModifiersPrice = item.defaultModifiersPrice || 0;
  if(item.price) {
    return `${formatPrice(item.price + defaultModifiersPrice)}`;
  } else if(item.prices?.length) {
    return `${formatPrice(item.prices[0]! + defaultModifiersPrice)}${item.prices.length > 1 ? '+' : ''}`;
  } else {
    return formatPrice(defaultModifiersPrice);
  }
};

// Copied from: https://github.com/toasttab/takeout-web/blob/main/client/components/Upsells/UpsellsProvider.jsx
export const getItemsFlat = (menus: any): UpsellItem[] =>
  menus.reduce((acc: any, menu: any) => {
    const menuName = menu.name;
    const items = menu.groups.reduce((itemsAcc: any, group: any) => {
      const groupName = group.name;
      return itemsAcc.concat(
        group.items.filter((item: any) => !item.outOfStock).map((item: any) => ({ ...item, groupName, menuName }))
      );
    }, []);
    return acc.concat(items);
  }, []);


// Copied from https://github.com/toasttab/takeout-web/blob/main/client/hooks/use-persist-equal-object.js
/**
 * Persists an object if deep equality between renders of a functional component.
 * Useful for hooks' dependency arrays that rely on shallow equality checking
 * @param {Object} object - An object to check between renders
 */
export const usePersistEqualObject = (object: any) => {
  const objectRef = useRef(object);

  useEffect(() => {
    if(!isEqual(object, objectRef.current)) {
      objectRef.current = object;
    }
  }, [object]);

  return objectRef.current;
};


export type UpsellItemsMapType = {
  [key: string]: boolean;
};
export const filterCartUpsellInfo = (upsellItemsMap: UpsellItemsMapType | null) => (_: any, item: any) => {
  if(!upsellItemsMap) return false;
  return upsellItemsMap[item.masterId] || false;
};


