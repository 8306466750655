import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { ORDER_KEY } from 'public/components/online_ordering/OrderContext';
import { CompletedOrder } from 'public/components/online_ordering/types';

const useAdyenPayments = (setShowAdyenOverlay: (b: boolean) => void) => {
  const history = useHistory();
  const { confirmationPath } = useRestaurantRoutes();
  const { cartGuid, clearCart } = useCart();
  const { placeOrder, setOrderError } = useCheckout();
  const { getValues } = useFormContext<CheckoutFormData>();

  const handleCompletedOrder = useCallback((completedOrder: CompletedOrder) => {
    if(completedOrder?.guid) {
      clearCart();
      setShowAdyenOverlay(false);
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      history.push(confirmationPath, { completedOrder });
    }
  }, [clearCart, setShowAdyenOverlay, history, confirmationPath]);

  const placeAdyenOrder = useCallback(async (paymentIntentID: string | undefined) => {
    const checkoutFormData = getValues();
    const formData = {
      ...checkoutFormData,
      semiPaymentIntentId: paymentIntentID
    };

    try {
      await placeOrder(cartGuid!, formData, handleCompletedOrder);
    } catch(error) {
      setOrderError(error);
    }
  }, [getValues, cartGuid, placeOrder, handleCompletedOrder, setOrderError]);

  return { placeAdyenOrder };
};

export default useAdyenPayments;
