import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch, UseFormReturn } from 'react-hook-form';

import classnames from 'classnames';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { CAInfo, ToastTOSAndPrivacy } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/legalUtils';
import Image from 'src/shared/components/common/Image';
import PhoneInput from 'src/shared/components/common/form_input/PhoneInput';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { ModalContent, useModalContext, ModalCloseButton } from 'shared/components/common/modal';
import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { alertSuccess } from 'shared/js/alertUtils';
import { asValidPhoneCountryCode } from 'shared/js/phoneUtils';

import { CompleteProfileForm } from 'public/components/default_template/online_ordering/account/completeProfileForm/CompleteProfileForm';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { ConfirmCodeForm, onCompleteProps } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';


type FormInputs = {
  yourInfoPhone: string;
}

export enum LoginStage {
  EnterPhone = 'EnterPhone',
  EnterVerificationCode = 'EnterVerificationCode',
  CompleteProfile = 'CompleteProfile'
}

export type AccountCreationFormInputs = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
}

export const PwlessAuth = ({
  source,
  skipLoginToast,
  onSuccessfulLogin,
  contentClassname,
  currentLoginStage = LoginStage.EnterPhone,
  accountInfo,
  showBackButton = true,
  secondaryCTAText,
  secondaryContent,
  customLoginHeader
}: {
  source: AuthenticationSource,
  skipLoginToast?: boolean,
  onSuccessfulLogin?: () => void,
  contentClassname?: string,
  currentLoginStage?: LoginStage,
  accountInfo?: AccountCreationFormInputs,
  showBackButton?: boolean,
  secondaryCTAText?: string,
  secondaryContent?: ReactNode,
  customLoginHeader?: string
}) => {
  const [loginStage, setLoginStage] = useState(currentLoginStage);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: { yourInfoPhone: accountInfo?.phoneNumber || '' }
  });
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });
  const [showSecondaryContent, setShowSecondaryContent] = useState(false);
  const withBackArrow = useMemo(() => loginStage != LoginStage.EnterPhone && showBackButton || showSecondaryContent, [loginStage, showBackButton, showSecondaryContent]);

  return (
    <ModalContent
      contentClassName={classnames(
        'modalContentV2 fullScreenMobile',
        contentClassname,
        loginStage === LoginStage.CompleteProfile ? 'completeProfile' : '',
        secondaryCTAText ? 'secondaryCTA' : ''
      )}
      data-testid="PwlessAuth"
      ariaLabelledBy="pwless-auth-modal-header">
      <div className={classnames('modalHeader', { withBackArrow })} data-testid="PwlessAuth">
        {withBackArrow &&
          <button aria-label="Return to phone input" className="backArrow" onClick={() => {
            if(showSecondaryContent) {
              setShowSecondaryContent(false);
            } else {
              setLoginStage(LoginStage.EnterPhone);
              formMethods.reset({ yourInfoPhone: '' });
            }
          }}>
            <Image alt="Back" src="icons/arrow-left-gray.svg" />
          </button>}
        <ModalCloseButton />
      </div>
      {showSecondaryContent
        ? secondaryContent :
        <div className="body">
          {loginStage === LoginStage.CompleteProfile
            ? <CompleteProfileForm accountInfo={accountInfo ?? { phoneNumber }} onSuccessfulLogin={onSuccessfulLogin} skipLoginToast={skipLoginToast} /> :
            <LoginForm
              formMethods={formMethods}
              loginStage={loginStage}
              setLoginStage={setLoginStage}
              source={source}
              onSuccessfulLogin={onSuccessfulLogin}
              customHeader={customLoginHeader}
              secondaryCTA={
                secondaryCTAText ?
                  <button type="button" className="cta secondary" onClick={() => setShowSecondaryContent(true)}>
                    <div className="text">{secondaryCTAText}</div>
                  </button>
                  : undefined
              }
              skipLoginToast={skipLoginToast ?? false} />}
        </div>}
    </ModalContent>
  );
};

const LoginForm = ({
  formMethods,
  loginStage,
  setLoginStage,
  source,
  skipLoginToast = false,
  onSuccessfulLogin,
  secondaryCTA,
  customHeader
}: {
  formMethods: UseFormReturn<FormInputs>,
  loginStage: LoginStage,
  setLoginStage: (loginStage: LoginStage) => void,
  source: AuthenticationSource,
  skipLoginToast: boolean,
  onSuccessfulLogin?: () => void,
  secondaryCTA?: ReactNode,
  customHeader?: string
}) => {
  const { onClose } = useModalContext();
  const { customer, passwordlessLogin, fetchCustomer, completeSignup, passwordlessConfirmCode } = useCustomer();
  const [error, setError] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);
  const tracker = useTracker();
  const ooRestaurant = useOptionalRestaurant()?.ooRestaurant;
  const isIntlRestaurant = useIsIntlRestaurant();

  const country = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });
  const parsedPhoneNumber = useMemo(() =>
    isValidPhoneNumber(phoneNumber, country) ? parsePhoneNumber(phoneNumber, country) : null
  , [country, phoneNumber]);

  const validatePhone = useCallback((value: string) =>
  // Don't use the memoized parsedPhoneNumber declared above because this is a callback to be passed for form validation
    isValidPhoneNumber(value, country) || 'Enter a valid local phone number'
  , [country]);

  const handlePhoneInputKeydown = async (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      await onSubmitPhone();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onSubmitPhone = useCallback(async () => {
    if(!parsedPhoneNumber) return;

    // If it is a valid local phone number in the country of the rx, send the code
    tracker.track('Clicked Send code', {
      source,
      countryCode: parsedPhoneNumber.country
    });

    setVerificationLoading(true);
    const success = await passwordlessLogin(parsedPhoneNumber.number);
    setVerificationLoading(false);

    if(success) {
      setError('');
      setLoginStage(LoginStage.EnterVerificationCode);
    } else {
      setError('Error sending confirmation code');
    }
  }, [setVerificationLoading, setLoginStage, passwordlessLogin, parsedPhoneNumber, tracker, source]);

  const onLogin = useCallback(async () => {
    const fetchedCustomer = await fetchCustomer();
    if(!fetchedCustomer) {
      setLoginStage(LoginStage.CompleteProfile);
    } else {
      onSuccessfulLogin?.();
      onClose();
      if(!skipLoginToast) alertSuccess(fetchedCustomer?.firstName ? `Welcome back, ${fetchedCustomer?.firstName}!` : 'Welcome back!');
      tracker.track('Completed 2FA', { source, newOrExistingAccount: 'existing account' });
      await completeSignup(fetchedCustomer?.email || '', fetchedCustomer?.firstName || '', fetchedCustomer?.lastName || '');
    }
  }, [fetchCustomer, setLoginStage, onSuccessfulLogin, onClose, skipLoginToast, tracker, source, completeSignup]);

  const onConfirmCodeComplete = useCallback(async ({ identity, code, setError }: onCompleteProps) => {
    const response = await passwordlessConfirmCode(identity, code);
    if(response) {
      await onLogin();
    } else {
      setError('The code you entered was incorrect. Please try again.');
    }
  }, [onLogin, passwordlessConfirmCode]);

  return (
    <FormProvider {...formMethods}>
      {verificationLoading && <LoadingSpinnerOverlay />}
      <div className="headerContent">
        <h2 className="title" id="pwless-auth-modal-header">{customHeader ?? (loginStage === LoginStage.EnterVerificationCode ? 'Enter verification' : 'Enter your phone number')}</h2>
        <p className="subtitle">
          {loginStage === LoginStage.EnterVerificationCode ? `Code sent to ${parsedPhoneNumber ? parsedPhoneNumber.formatNational() : phoneNumber}` : 'We\'ll send you a code to log in or sign up.'}
        </p>
      </div>
      <div id="pwlessauthcontent">
        {loginStage === LoginStage.EnterVerificationCode && parsedPhoneNumber ?
          <ConfirmCodeForm
            identity={parsedPhoneNumber.number}
            onComplete={onConfirmCodeComplete}
            sendCode={onSubmitPhone} /> :
          <div className="phoneForm">
            <PhoneInput
              id="yourInfoPhone"
              aria-labelledby="pwlessauthcontent"
              required
              label="Phone number"
              validate={validatePhone}
              autoComplete="tel"
              defaultValue={customer?.phone || ''}
              warning={error}
              filled={Boolean(customer?.phone)}
              onKeyDown={handlePhoneInputKeydown}
              unregisterOnUnmount={false}
              shouldUnFocus={false}
              initialFocus={true}
              isSingletonField={true} />
            <button type="button" className="cta submitPhoneButton" onClick={onSubmitPhone} disabled={!parsedPhoneNumber}>
              <div className="text"><span>Continue with</span><Image alt="Toast" src="icons/toast-logo-white-with-text.svg" /></div>
            </button>
            {secondaryCTA}
          </div>}
      </div>
      <p className="legal" role="contentinfo">
        {getLegalText(isIntlRestaurant, loginStage)}
      </p>
    </FormProvider>
  );
};

const getLegalText = (isIntlRestaurant: boolean, loginStage: LoginStage) => {
  return loginStage === LoginStage.EnterPhone ?
    <>
        By providing a mobile number, you give Toast permission to send you a one-time verification code. Message and data rates may apply.{' '}
        Subject to <ToastTOSAndPrivacy />. {!isIntlRestaurant && <CAInfo />}
    </>
    :
    <>
        By continuing, your name and email address may be shared with this restaurant/restaurant group.{' '}
        Subject to <ToastTOSAndPrivacy /> and Merchant&apos;s Terms and Policies apply. {!isIntlRestaurant && <CAInfo />}
    </>;
};
