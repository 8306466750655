import React, { createContext, useContext, useMemo } from 'react';

import _ from 'lodash';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { UpsellItem } from 'public/components/default_template/online_ordering/upsell/UpsellItem';
import {
  RuleContext, intelligentUpsellFilterRules, menuGroupsInCartFilter, notInCartFilter,
  upsellSortRules
} from 'public/components/default_template/online_ordering/upsell/utils/upsellRules';
import { filterCartUpsellInfo, getItemsFlat, UpsellItemsMapType, usePersistEqualObject } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';


type UpsellContextType = {
  upsells: UpsellItem[]
}

export const UpsellContext = createContext<UpsellContextType | undefined>(undefined);


// Copied from: https://github.com/toasttab/takeout-web/blob/main/client/components/Upsells/UpsellsProvider.jsx
export const UpsellContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { cart } = useCart();
  const { selectedLocation } = useRestaurant();
  const { menus: maybeMenu, popularItems: maybePopularItems } = useOOMenus({});
  const { menus, popularItems } = useMemo(() => {
    const _menus = maybeMenu || [];
    const _popularItems = maybePopularItems || [];
    return { menus: _menus, popularItems: _popularItems };
  }, [maybeMenu, maybePopularItems]);

  const cartUpsellItems = useMemo(() => {
    if(cart) {
      const _cartUpsellItems: string[] = cart?.cartUpsellInfo?.upsellItems || [];
      if(_cartUpsellItems.length) {
        return _cartUpsellItems.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {} as UpsellItemsMapType);
      }
    }
    return null;
  }, [cart]);

  const filterRules = usePersistEqualObject(intelligentUpsellFilterRules);
  const sortRules = usePersistEqualObject(upsellSortRules);

  const upsells = useMemo(() => {
    if(selectedLocation.upsells && !selectedLocation.upsells.enabled) {
      return [];
    }

    let customUpsells: UpsellItem[] = [];
    let customUpsellsGuids: Set<string> = new Set();
    let customUpsellMasterIds: string[] = [];

    const items = getItemsFlat(menus);
    const ctx = { cart: _.cloneDeep(cart), popularItems: _.cloneDeep(popularItems) };

    if(selectedLocation.upsells?.enabled && !selectedLocation.upsells.useDefaults) {
      customUpsellMasterIds = selectedLocation.upsells.upsellMappings
        .filter(mapping => menuGroupsInCartFilter(ctx, mapping)).flatMap(mapping => mapping.menuItemMasterId);
      customUpsells = items.filter(item => {
        // Filter out duplicates
        if(!customUpsellsGuids.has(item.guid) &&
        customUpsellMasterIds.includes(item.masterId)) {
          customUpsellsGuids.add(item.guid);
          return true;
        }
        return false;
      })
        .filter(item => notInCartFilter(ctx as RuleContext, item as any))
        .sort((a, b) => customUpsellMasterIds.indexOf(a.masterId) - customUpsellMasterIds.indexOf(b.masterId));
    }

    const allFilterRules = [
      filterCartUpsellInfo(cartUpsellItems),
      ...filterRules
    ];

    const filtered = allFilterRules.reduce((acc, rule) => {
      return acc.filter((...args: any) => rule(ctx, ...args));
    }, items);

    const sorted = sortRules.reduce((acc: any, rule: any) => {
      return acc.sort((...args: any) => rule(ctx, ...args));
    }, filtered);

    return [...customUpsells, ...sorted?.filter((item: any) => !customUpsellMasterIds.includes(item.masterId)) || []]?.slice(0, 3);
  }, [cart, cartUpsellItems, filterRules, menus, popularItems, sortRules, selectedLocation]);

  const context = { upsells };
  return (
    <UpsellContext.Provider value={context}>
      {props.children}
    </UpsellContext.Provider>);
};

export const useUpsell = () => {
  const context = useContext(UpsellContext);
  if(!context) {
    throw new Error('useUpsell must be used within a UpsellContextProvider');
  }

  return context;
};
