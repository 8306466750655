import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ContainsEditableProps, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { DoMenus_GetMenuGroupsGroup as DoMenusGetMenuGroupsGroup, DoMenus_GetMenuItemsItem as DoMenusGetMenuItemsItem } from 'src/apollo/onlineOrdering';
import { MenuGroupIdentifier } from 'src/apollo/sites';
import { formatImageURL } from 'src/lib/js/utilities';

import Image from 'shared/components/common/Image';
import { useBulkCalls } from 'shared/components/common/dynamic_menus/BulkCallsContext';
import { getItemImage } from 'shared/components/common/dynamic_menus/DynamicMenusUtils';
import { MenuItemBlockBackupContent } from 'shared/components/common/dynamic_menus/menu_item/MenuItem';
import Link from 'shared/components/common/link';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import FormattedPrice from 'shared/components/common/price/FormattedPrice';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { getOrderPath } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { menuItemPath } from 'public/components/default_template/menu_section/MenuItemCard';


type TestProps = { testId?: string }

type GroupItemBackupContent = {guid: string} & MenuItemBlockBackupContent;

export type MenuGroupBlockBackupContent = {
  name: string
  description: string
  items: GroupItemBackupContent[]
}

export type MenuGroupBlockConfig = {
  appearance: {
    showPrice: boolean
  }
}

type EditableProps = {
  classNames?: string;
  editableRef?: any;
  id: MenuGroupIdentifier
  backupContent: MenuGroupBlockBackupContent
  config: MenuGroupBlockConfig | null
} & TestProps & ContainsEditableProps;

const placeholderMenuGroup = {
  name: 'Menu Group',
  items: [
    { guid: uuidv4() },
    { guid: uuidv4() },
    { guid: uuidv4() },
    { guid: uuidv4() }
  ]
} as DoMenusGetMenuGroupsGroup;

const EditableMenuGroup = (props: EditableProps) => {
  const { menuGroups: maybeMenuGroups } = useBulkCalls();
  const { editPath, id, backupContent } = props;

  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'menu group',
    displayName: 'Menu Group',
    actions: ['delete'],
    path: editPath,
    schema: { fields: [] }
  });

  const menuGroup = useMemo(() => {
    if(maybeMenuGroups.length > 0) {
      return maybeMenuGroups.find(group => group.guid === id.groupGuid) || backupContent || placeholderMenuGroup;
    } else {
      return backupContent || placeholderMenuGroup;
    }
  }, [backupContent, id.groupGuid, maybeMenuGroups]);

  return (
    <div ref={editableRef}>
      {menuGroup &&
          <div className="groupContainer">
            <div className="groupWrapper">
              <div className="groupDetails">
                <div className="groupName">{menuGroup.name || backupContent?.name || placeholderMenuGroup.name}</div>
                <div className="groupDescription">{menuGroup.description || backupContent?.description}</div>
              </div>
              <div className="groupItems">
                {
                  menuGroup.items.filter(item => !!item)
                    .map(item => {
                      return (
                        <LinkedItem item={item} key={item.guid} {...props} />
                      );
                    })
                }
              </div>
            </div>
          </div>}
    </div>
  );
};

type LinkedItemProps = {
  item: DoMenusGetMenuItemsItem | GroupItemBackupContent
} & EditableProps

const LinkedItem = (props: LinkedItemProps) => {
  const { item, config } = props;
  const { restaurant, locations } = useRestaurant();
  const hasOoPage = restaurant.config?.isOnlineOrderingEnabled && !restaurant.config.ooConfig?.optedOut;

  const linkHref = useMemo(() => {
    if(!hasOoPage || !locations) {
      return null;
    } else if(locations?.length === 1 && locations[0]?.shortUrl && item.guid && item.name) {
      // If there is just one location, link directly to the item.
      return menuItemPath(
        getOrderPath(locations[0]?.shortUrl),
        item.name,
        item.guid
      );
    } else {
      // If there is more than one location, don't assume which location the user
      // would like to see. Fallback to the order page if there is a data issue.
      return '/order';
    }
  }, [hasOoPage, item.guid, item.name, locations]);

  const itemComponent = useMemo(() => {
    const hasDescr = !!item.description;

    return (
      <div className={classnames('itemWrapper', { leftAligned: hasDescr, leftAlignedNoDescr: !hasDescr })} key={item.guid}>
        <div className="itemName">{item.name || 'Menu Item'}</div>
        <div className="itemDescription">{item.description}</div>
        <div className="itemPrice">{config?.appearance?.showPrice ? <FormattedPrice value={(item.price || 0) as number} /> : ''}</div>
        <div className="itemImage"><Image alt={''} src={formatImageURL(getItemImage(item))} className={'itemImageWrapper'} /></div>
        <div className="itemDelimiter"></div>
      </div>
    );
  }, [config?.appearance?.showPrice, item]);

  return linkHref
    ? <Link href={linkHref} key={item.guid}>{itemComponent}</Link>
    : itemComponent;
};

const WrappedEditableMenuGroup = (props: EditableProps) => {
  const ooClient = useOOClient();
  return (
    <ApolloProvider client={ooClient}>
      <EditableMenuGroup {...props} />
    </ApolloProvider>
  );
};


export default WrappedEditableMenuGroup;
