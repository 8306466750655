import { type TagManagerArgs, type DataLayerArgs } from 'react-gtm-module';

import Snippets from './Snippets';

const TagManager = {
  dataScript: function(dataLayer: string) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm: function(args: any) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript
    };
  },
  initialize: function({ gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer', auth = '', preview = '' }: TagManagerArgs) {
    const gtm = this.gtm({
      id: gtmId,
      events: events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      auth,
      preview
    });
    if(dataLayer) document.head.appendChild(gtm.dataScript);
    const firstHeadElement = document.head.childNodes[0];
    const firstBodyElement = document.body.childNodes[0];
    if(firstHeadElement) {
      document.head.insertBefore(gtm.script(), firstHeadElement);
    }
    if(firstBodyElement) {
      document.body.insertBefore(gtm.noScript(), firstBodyElement);
    }
  },
  dataLayer: function({ dataLayer, dataLayerName = 'dataLayer' }: DataLayerArgs) {
    const thisWindow = window as any;
    if(thisWindow[dataLayerName]) return thisWindow[dataLayerName].push(dataLayer);
    if(dataLayer) {
      const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
      const dataScript = this.dataScript(snippets);
      const firstHeadChild = document.head.childNodes[0];
      if(firstHeadChild) {
        document.head.insertBefore(dataScript, firstHeadChild);
      } else {
        document.head.appendChild(dataScript);
      }
    }
  }
};

export default TagManager;
