import React, { useMemo, useState } from 'react';

import { FieldType, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import isURL from 'validator/lib/isURL';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { HeroContainer } from 'src/apollo/sites';

import Image, { ImageProps } from 'shared/components/common/Image';
import { formatAddress } from 'shared/components/common/form_input/LocationInput';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';
import FulfillmentControl from 'public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentControl';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';


const MenuPageHeader = ({ heading, allowFulfillmentChange }: { heading: string, allowFulfillmentChange?: boolean }) => {
  const { restaurant: { config } } = useRestaurant();
  const { useEditableRef } = useEditor();
  const image = config.ooConfig?.heroImage;
  const withoutImage = !image?.src;
  const centerHero = !withoutImage && config.ooConfig?.heroContainer === HeroContainer.Center; // undefined should also be treated as full width
  const heroBackground = config.ooConfig?.heroBackground;
  const roundedCornerStyle = useMemo(
    () => config.ooConfig && centerHero ? overrideConfigBasedOnTemplate({ format: config.ooConfig.format, colors: config.ooConfig.colors }, config).roundedCorner : undefined,
    [config, centerHero]
  );

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'menu hero section',
    actions: [],
    schema: {
      fields: [
        {
          path: 'config.ooConfig.heroImage',
          displayName: 'Image',
          type: FieldType.Image,
          value: image,
          deletable: true
        }
      ]
    }
  });

  const imageProps: ImageProps = { alt: image?.altText || 'Banner image', className: roundedCornerStyle };

  return (
    <div ref={editableRef} className={classnames('menuHeader', { withoutImage })} data-testid="menu-header">
      {withoutImage ?
        <div className="heroBanner withoutImage" data-testid="hero-without-image"
          style={{ background: heroBackground?.backgroundColor ?? undefined }}>
          <HeaderContent heading={heading} allowFulfillmentChange={allowFulfillmentChange} color={heroBackground?.textColor ?? undefined} />
        </div> :
        <>
          <div className={classnames('heroBanner', centerHero && 'paddedContentWrapper')} data-testid="hero-with-image">
            <div className={classnames('heroImage', centerHero && 'paddedContent', { centerHero })} data-testid="hero-image">
              {/* Don't use the image object directly if the original source is a fully qualified path,
              since that means it was probably pulled from Toast's CDN and can't use the Imgproxy path */}
              {isURL(image.src, { require_host: true }) ?
                <Image src={image.src} aria-hidden {...imageProps} /> :
                <Image imageObject={image} aria-hidden {...imageProps} />}
            </div>
          </div>
          <HeaderContent heading={heading} allowFulfillmentChange={allowFulfillmentChange} />
        </>}
    </div>
  );
};

const HeaderContent = ({ heading, allowFulfillmentChange, color }: { heading: string, allowFulfillmentChange?: boolean, color?: string }) => {
  const { orderingDisabled } = useRestaurant();
  const { setFulfillmentModalOpen } = useFulfillment();
  const [fulfillmentModalLoading, setFulfillmentModalLoading] = useState(false);

  return (
    <div className="contentWrapper paddedContentWrapper">
      <div className="content paddedContent">
        <div className="headerContent" style={{ color }}>
          <h1 className={classnames('heading', { smaller: heading.length > 30 })}>{heading}</h1>
          {!orderingDisabled && allowFulfillmentChange ? <DiningOptionBehaviorMessage /> : null}
        </div>
        {allowFulfillmentChange &&
            <div className="fulfillmentControlContainer hidden-xs">
              <FulfillmentControl onClick={async () => {
                setFulfillmentModalLoading(true);
                await setFulfillmentModalOpen(true);
                setFulfillmentModalLoading(false);
              }} loading={fulfillmentModalLoading} isMobile={false} testId="fulfillment-control-desktop" />
            </div>}
      </div>
    </div>
  );
};

const DiningOptionBehaviorMessage = () => {
  const { cart } = useCart();
  const { validDeliveryAddress } = useDelivery();
  const { fulfillmentData, setFulfillmentModalOpen } = useFulfillment();
  const { orderingDisabled, selectedLocation } = useRestaurant();

  const fulfillmentBehavior = useMemo(
    () => fulfillmentData?.cartFulfillmentData.diningOptionBehavior ?? DiningOptionBehavior.TakeOut,
    [fulfillmentData?.cartFulfillmentData.diningOptionBehavior]
  );
  const deliveryAddress = useMemo(() => cart ? cart.order?.deliveryInfo : validDeliveryAddress, [cart, validDeliveryAddress]);

  const infoContent = useMemo(() => {
    if(orderingDisabled) {
      return <>We are not accepting online orders right now.</>;
    } else if(fulfillmentBehavior === DiningOptionBehavior.TakeOut) {
      return <>{formatAddress(selectedLocation)}</>;
    } else if(fulfillmentBehavior === DiningOptionBehavior.Delivery && deliveryAddress) {
      return <>{`Delivering to ${formatDeliveryAddress(deliveryAddress, true)}`}</>;
    }
    return null;
  }, [orderingDisabled, fulfillmentBehavior, selectedLocation, deliveryAddress]);

  if(!infoContent) {
    return null;
  }

  return (
    <div role="button" className="hidden-xs" onClick={() => setFulfillmentModalOpen(true)}>
      <div className="diningOptionBehaviorInfo">
        {infoContent}
      </div>
    </div>
  );
};


export default MenuPageHeader;
