import React, { memo, useEffect, useState, useRef } from 'react';

import classnames from 'classnames';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import Image from 'shared/components/common/Image';
import AdyenPaymentOverlay from 'shared/components/common/loading_spinner/AdyenPaymentOverlay';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutNote from 'public/components/default_template/online_ordering/checkout/CheckoutNote';
import { useIsCartValid } from 'public/components/default_template/online_ordering/checkout/useIsCartValid';
import { OrderError, useCheckout } from 'public/components/online_ordering/CheckoutContext';

import AdyenPaymentForm from './AdyenPaymentForm';

const InternationalPaymentContainer = memo(({ expanded, hideIntlPayment }: { expanded: boolean, hideIntlPayment: () => void }) => {
  const { ooRestaurant } = useRestaurant();
  const { orderError, setOrderError } = useCheckout();

  const { isCartValid } = useIsCartValid();

  const onlineOrderingEnabled = ooRestaurant?.onlineOrderingEnabled;

  const [showAdyenOverlay, setShowAdyenOverlay] = useState<boolean>(false);

  const orderErrorRef = useRef<OrderError | null>(null);

  useEffect(() => {
    if(orderError) {
      setShowAdyenOverlay(false);
      orderErrorRef.current = orderError;
    } else {
      orderErrorRef.current = null;
    }
  }, [orderError, setOrderError, isCartValid]);

  if(!onlineOrderingEnabled) {
    return <div data-testid="paymentUnavailable">Online ordering payments unavailable</div>;
  }

  return (
    <>
      {showAdyenOverlay && <AdyenPaymentOverlay fullScreen />}
      <AnimatedSection expanded={expanded}>
        <div className="internationalPaymentContainer">
          <div className={classnames('internationalPaymentHeader collapsable')} onClick={() => hideIntlPayment()}>
            Payment details
            <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
          </div>

          <div data-testid={'internationalPaymentContainer'}>
            {/* Rendering AdyenPaymentForm will create a payment intent. Only do that when the user is ready to pay. */}
            {expanded && <AdyenPaymentForm setShowAdyenOverlay={setShowAdyenOverlay} orderErrorRef={orderErrorRef} />}
            <p data-testid="intlPaymentLegalStatement" className="note checkoutNote" role="contentinfo">
              I understand my information will be used to process this payment and for other purposes outlined in Toast&apos;s{' '}
              <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>, and will also be disclosed to the merchant.
            </p>
          </div>
        </div>
        <CheckoutNote />
      </AnimatedSection>
    </>
  );
});
InternationalPaymentContainer.displayName = 'InternationalPaymentContainer';

export default InternationalPaymentContainer;
