import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';


import { IdentityType, useAddGuestIdentityMutation, useUpdateBasicInfoMutation } from 'src/apollo/onlineOrdering';
import { profileInformationSchema } from 'src/public/components/default_template/online_ordering/account/form-helpers';
import VerifyEmailModal from 'src/public/components/default_template/online_ordering/account/verifyEmailModal/VerifyEmailModal';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import Image from 'src/shared/components/common/Image';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import InputField from 'src/shared/components/common/form_input/InputField';
import LoadingSpinnerOverlayWrapper from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { alertError, alertSuccess } from 'shared/js/alertUtils';


interface ProfileInformationProps { isLoggedOut?: boolean; }

const toastSuccess = () => {
  alertSuccess('Your name has been updated');
};

const toastFail = () => {
  alertError('There was an error while saving. Please refresh the page.');
};

const toastEmailFailGeneric = () => {
  alertError('There was an error while updating email. Please try again.');
};

export const ProfileInformation: FC<ProfileInformationProps> = ({ isLoggedOut }) => {
  const { customer } = useCustomer();
  const [firstName, setFirstName] = useState(customer?.firstName);
  const [lastName, setLastName] = useState(customer?.lastName);
  const ooClient = useOOClient();
  const [updateBasicInfo] = useUpdateBasicInfoMutation();
  const [addGuestIdentity] = useAddGuestIdentityMutation();
  const { discAllowEmailIdentities } = useFlags();
  const [updatedEmail, setUpdatedEmail] = useState('');

  const [loading, setLoading] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileInformationSchema),
    defaultValues: {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      phone: customer?.phone
    }
  });

  useEffect(() => {
    if(isLoggedOut) {
      return formMethods.reset({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      });
    }

    return formMethods.reset({
      firstName: firstName || customer?.firstName,
      lastName: lastName || customer?.lastName,
      email: updatedEmail || customer?.email,
      phone: customer?.phone
    });
  }, [formMethods, customer, formMethods.reset, isLoggedOut, updatedEmail, firstName, lastName]);

  const sendCode = useCallback(async (email: string) => {
    return await addGuestIdentity({
      variables: {
        input: {
          identityType: IdentityType.Email,
          identityString: email
        }
      },
      client: ooClient
    });
  }, [addGuestIdentity, ooClient]);


  const updateGuestsBasicInfo = useCallback(async (data: any, didUpdateEmail: boolean) => {
    if(customer?.firstName !== data.firstName || customer?.lastName !== data.lastName) {
      const phone = data.phone;
      const firstName = data.firstName;
      const lastName = data.lastName;
      try {
        const { data } = await updateBasicInfo({
          variables: {
            input: {
              firstName,
              lastName,
              phone
            }
          },
          client: ooClient
        });
        if(data?.updateBasicInfo.__typename === 'UpdateBasicInfoResponse') {
          toastSuccess();
        } else {
          toastFail();
        }
      } catch(err) {
        toastFail();
      }
    }

    setUpdatedEmail('');

    didUpdateEmail && alertSuccess('Email updated');
  }, [updateBasicInfo, ooClient, customer]);

  const didUpdateGuestsEmail = useCallback(async (data: any) => {
    const email = data.email;
    if(customer?.email && email !== customer?.email) {
      setLoading(true);
      try {
        const { data } = await sendCode(email);
        const response = data?.addGuestIdentity;
        setLoading(false);
        switch(response?.__typename) {
          case 'AddGuestIdentitySuccess':
            setUpdatedEmail(email);
            return true;
          case 'GuestIdentityAlreadyExistsForCurrentGuestError':
          case 'InvalidEmailError':
          case 'ProfileLockedForDeletionError':
          case 'UnexpectedError':
            alertError(response.message);
            break;
        }
      } catch(err) {
        setLoading(false);
        toastEmailFailGeneric();
      }
    }
    return false;
  }, [customer, sendCode]);

  const onSubmit = useCallback(async (data: any) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    const updatedEmail = discAllowEmailIdentities && await didUpdateGuestsEmail(data);
    if(updatedEmail) return; // If guest attempts to update their email, the closing actions on the modal will trigger the basic account updates.
    updateGuestsBasicInfo(data, updatedEmail);
  }, [didUpdateGuestsEmail, updateGuestsBasicInfo, discAllowEmailIdentities]);

  const disabled = !(formMethods.formState.isValid && formMethods.formState.isDirty) || formMethods.formState.isSubmitting || isLoggedOut;

  return (
    <div className="profileInformation" data-testid="ProfileInformation" role="form">
      <h2 className={isLoggedOut ? 'accountSubheadingDisabled' : 'accountSubheading'}>My Information</h2>
      <div className="infoWrapper">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputField disabled={isLoggedOut} id="firstName" type="text" label="First name" required />
            <InputField disabled={isLoggedOut} id="lastName" type="text" label="Last name" required />
            <InputField disabled={isLoggedOut} id="email" type="text" label="Email" {...(discAllowEmailIdentities ? { required: true } : { readOnly: true })} />
            <InputField disabled={isLoggedOut} id="phone" type="text" label="Phone" readOnly={true} />
            <button type="submit" className="submitPhoneButton" disabled={disabled} aria-live="polite" aria-disabled={disabled}>
              <div className="text"><span>Update account on </span><Image alt="Toast" src="icons/toast-logo-white-with-text.svg" /></div>
            </button>

            {loading && <LoadingSpinnerOverlayWrapper />}
            {discAllowEmailIdentities && updatedEmail && <VerifyEmailModal
              email={updatedEmail}
              onCloseModal={async (didUpdateEmail: boolean) => updateGuestsBasicInfo(formMethods.getValues(), didUpdateEmail)}
              resendCode={sendCode} />}
          </form>
        </FormProvider>
      </div>
      <div className="sectionSeparatorWrapper">
        <hr className="sectionSeparator" />
      </div>
    </div>
  );
};

export default ProfileInformation;
