import React, { ReactNode, useMemo, useRef, useState } from 'react';

import { CopyIcon } from '@toasttab/buffet-pui-icons';
import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import 'swiper/css';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { RankedPromoOfferDiscount } from 'src/apollo/onlineOrdering';
import { MenuConfig } from 'src/apollo/sites';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';

import ChevronIcon from 'shared/components/common/carousel/ChevronIcon';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';
import { FreeDeliveryIllustration, StickerIllustration, TagIllustration } from 'public/components/default_template/offers/OffersIcons';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';

import { LoyaltyCard, LoyaltyCardType } from './LoyaltyCard';

export enum DiscountType {
  DOLLAR = 'Dollar',
  PERCENT = 'Percent'
}

const Offers = () => {
  const { restaurant, ooPromoBanners, ooRestaurant } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();
  const { rankedPromoOfferDiscounts } = useOffers();
  const swiperRef = useRef(null);
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slidesPerView = isMobile ? 1 : 2;
  const { useEditableRef } = useEditor();
  const { loadingLoyaltyFeatures, programDescription, hasLoyaltyAccount } = useLoyalty();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'promo codes',
    displayName: 'Promo Banner',
    actions: [],
    path: 'config.ooConfig.promoCodeConfig',
    schema: { fields: [] }
  });
  const promoCodeConfig = restaurant.ooConfig?.promoCodeConfig;
  const deliveryBanner = ooPromoBanners?.deliveryBanners?.[0];
  const loyaltyBanner = ooPromoBanners?.loyaltyBanners?.[0];

  const [illustrationColor, arrowColor] = useMemo(() => {
    return getColorFromTheme(theme => [
      theme.colorOverrides?.offerColorOverrides?.illustration ?? theme.colorScheme.background.primary,
      theme.colorScheme.icon.default
    ], [
      promoCodeConfig?.backgroundColor ?? restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary,
      '#252525'
    ]);
  }
  , [getColorFromTheme, promoCodeConfig?.backgroundColor, restaurant.meta.primaryColor]);

  const offerSlides = useMemo(() => {
    const freeDeliverySlide = deliveryBanner ?
      [
        <SwiperSlide key={deliveryBanner.bannerGuid} data-testid="swiper-slide">
          <Offer
            offer={deliveryBanner as RankedPromoOfferDiscount}
            illustrationType="freeDelivery"
            illustrationColor={illustrationColor}
            isFreeDeliveryOffer />
        </SwiperSlide>
      ]
      : [];

    // Only show the accrual bonus slide if the loyalty banner is also present and the user has a loyalty account
    const loyaltyAccrualBonusSlide = loyaltyBanner && hasLoyaltyAccount ?
      [
        <SwiperSlide key="loyaltyAccrualBonus" data-testid="swiper-slide">
          <LoyaltyCard type={LoyaltyCardType.AccrualBonus} />
        </SwiperSlide>
      ]
      : [];

    const loyaltySlide = ooRestaurant?.loyaltyConfig?.loyaltySignupEnabled && !loadingLoyaltyFeatures && programDescription ?
      [
        <SwiperSlide key="loyalty" data-testid="swiper-slide">
          <LoyaltyCard />
        </SwiperSlide>
      ]
      : [];

    const promoOfferDiscountSlides = rankedPromoOfferDiscounts?.map((offer, index) =>
      <SwiperSlide key={offer.bannerGuid} data-testid="swiper-slide">
        <Offer
          offer={offer as RankedPromoOfferDiscount}
          illustrationType={index % 2 === 0 ? 'sticker' : 'tag'} // alternate sticker and tag icons
          illustrationColor={illustrationColor} />
      </SwiperSlide>) ?? [];

    return [...loyaltySlide, ...loyaltyAccrualBonusSlide, ...freeDeliverySlide, ...promoOfferDiscountSlides];
  }, [deliveryBanner, hasLoyaltyAccount, illustrationColor, loadingLoyaltyFeatures, loyaltyBanner, ooRestaurant?.loyaltyConfig?.loyaltySignupEnabled, programDescription, rankedPromoOfferDiscounts]);

  const sectionHeader = useMemo(() =>
    ooRestaurant?.loyaltyConfig?.loyaltySignupEnabled && ooRestaurant.loyaltyConfig.programName ? 'Rewards & Savings' : 'Offers & Savings', [ooRestaurant]);

  if(offerSlides.length === 0) {
    return null;
  }

  return (
    <div className="offers" ref={editableRef} data-testid="offers">
      <div className="offersHeader">
        <h3 className="header">{sectionHeader}</h3>
        {offerSlides.length > slidesPerView &&
          <div className="arrows">
            <button ref={navigationPrevRef} aria-label="Scroll left" className={classnames('arrow left', { disabledArrow: activeIndex === 0 })}>
              <ChevronIcon color={arrowColor} />
            </button>
            <button ref={navigationNextRef} aria-label="Scroll right" className={classnames('arrow right', { disabledArrow: activeIndex === offerSlides.length - slidesPerView })}>
              <ChevronIcon color={arrowColor} />
            </button>
          </div>}
      </div>
      <Swiper
        ref={swiperRef}
        className="offerAndLoyaltyCards"
        slidesPerView={slidesPerView}
        spaceBetween={24}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        mousewheel={offerSlides.length > slidesPerView}
        onActiveIndexChange={(swiperData: SwiperClass) => {
          setActiveIndex(swiperData.activeIndex);
        }}
        modules={[Mousewheel, Navigation]}>
        {offerSlides}
      </Swiper>
    </div>
  );
};

const Offer = ({
  offer,
  illustrationType,
  illustrationColor,
  isFreeDeliveryOffer
}: {
  offer: RankedPromoOfferDiscount,
  illustrationType: 'sticker' | 'tag' | 'freeDelivery',
  illustrationColor: string,
  isFreeDeliveryOffer?: boolean
}) => {
  const { restaurant: { config } } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();
  const { cart } = useCart();
  const { isOpen, onClose, onOpen } = useModal();
  const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);
  const appliedDiscountCode = cart?.order?.discounts?.restaurantDiscount?.promoCode;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const templateOverrides = useMemo(() => overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config), [config, config.ooConfig]);
  const discountType = offer.discount?.amountType === 'FIXED' || offer.discount?.bogoAction?.amountType === 'FIXED' ? DiscountType.DOLLAR : DiscountType.PERCENT;

  const [backgroundColor, textColor, borderColor] = getColorFromTheme(theme => {
    return [
      theme.colorOverrides?.onlineOrderingMenu?.cardBackground ?? theme.colorScheme.surface.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.itemTitle ?? theme.colorScheme.text.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.cardBorder ?? theme.colorScheme.border.default
    ];
  },
  [
    config.ooConfig?.colors?.background ?? DEFAULT_COLORS.background ?? '#FFFFFF',
    config.ooConfig?.colors?.primaryText ?? DEFAULT_COLORS.text,
    templateOverrides?.borderColor
  ]);

  const illustration =
    illustrationType === 'freeDelivery' ?
      <FreeDeliveryIllustration color={illustrationColor} /> :
      illustrationType === 'tag' ?
        <TagIllustration color={illustrationColor} discountType={discountType} /> :
        <StickerIllustration color={illustrationColor} discountType={discountType} />;

  const copyPromoCode = async () => {
    await navigator.clipboard.writeText(offer.promoCode);
    setCopiedMessageVisible(true);
    setTimeout(() => {
      setCopiedMessageVisible(false);
    }, 2000);
  };

  const copyButton =
    <div className="copyButton">
      <button onClick={copyPromoCode} aria-label="Copy promo code"><CopyIcon /></button>
      <div className={classnames('copyMessage', { show: copiedMessageVisible })}>Copied!</div>
    </div>;

  const isSingleUseOffer = offer.discount?.promoCodes && offer.discount.promoCodes.length > 0 && offer.discount.promoCodes[0]?.singleUse;

  return (
    <>
      <button
        className={classnames('offerAndLoyaltyCard',
          templateOverrides.borderStroke,
          templateOverrides?.roundedCorner,
          templateOverrides?.dropShadow)}
        style={{ borderColor, backgroundColor, color: textColor }}
        onClick={onOpen}
        data-testid={`offer-${offer.bannerGuid}`}>
        <div className="offerInfo">
          <div className="offerText" data-testid="offer-text">{offer.bannerText}</div>
          <div className="applyText">{isFreeDeliveryOffer ? 'Automatically applied at checkout' : appliedDiscountCode === offer.promoCode ? 'Applied!' : 'Apply at checkout'}</div>
        </div>
        <div
          className="offerIllustration"
          data-testid={`offer-illustration-${illustrationType}-${discountType}`}
          style={{ background: `linear-gradient(270deg, ${illustrationColor}22, transparent)` }}>
          {illustration}
        </div>
      </button>
      <Modal isOpen={isOpen} onClose={onClose} className="offerModalWrapper">
        <ModalOverlay fadeIn fadeOut />
        <ModalContent contentClassName="offerModal">
          <ModalCloseButton />
          <div className="content" data-testid="offer-modal">
            <div className="header" style={{ background: `linear-gradient(0deg, white, ${illustrationColor}22)` }}>
              {illustration}
              <div className="title">{offer.bannerText}</div>
              <div>{isFreeDeliveryOffer ? 'Automatically applied at checkout' : 'Apply at checkout'}</div>
            </div>
            <div className="body">
              { isSingleUseOffer &&
                <OfferDetail
                  title="RESTRICTIONS"
                  description="This is a single-use offer. If you've already redeemed it, you won't be able to redeem it a second time." />}
              {offer.promoCode &&
                  <OfferDetail title="PROMO CODE" description={offer.promoCode} action={copyButton} />}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const OfferDetail = ({ title, description, action }: {title: string, description: string, action?: ReactNode}) => {
  return (
    <div className="offerDetail">
      <div className="detail">
        <div className="detailTitle">{title}</div>
        <div className="detailDescription">{description}</div>
      </div>
      {action ? action : null}
    </div>
  );
};

export default Offers;
