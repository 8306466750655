import React from 'react';

import classnames from 'classnames';

import Warning from 'shared/components/common/warning/Warning';

import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { ToastPaymentType } from 'public/components/online_ordering/types';

import PlaceOrderTipWarning from './PlaceOrderTipWarning';
import useCanSubmitOrder from './useCanSubmitOrder';

export const SubmitButton = ({ dataTestid, label, onClick }: { dataTestid: string, label: string, onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
  const { showRemoveRoundupTemporaryMessaging } = usePayment();
  const disabled = !useCanSubmitOrder();

  return (
    <>
      <button
        data-testid={dataTestid}
        type="submit"
        className={classnames('submitButton primaryCta', !disabled && 'primaryColorBackground primaryColorHover')}
        disabled={disabled}
        onClick={onClick}>
        {label}
      </button>
      {showRemoveRoundupTemporaryMessaging &&
        <Warning dataTestId="remove-round-up-warning" message="Error: please remove the Round Up amount to place the order" />}
      <PlaceOrderTipWarning />
    </>
  );
};

const SubmitOrderButton = () => {
  const { paymentOption, paymentType } = usePayment();
  const { orderTotal } = useCheckout();
  const disabled = !useCanSubmitOrder();

  if((paymentOption === PaymentOption.ApplePay || paymentType === ToastPaymentType.APPLE_PAY) && orderTotal > 0) {
    return <button data-testid="applePaySubmitButton" type="submit" className="submitButton applePayBtn" disabled={disabled} />;
  }

  return <SubmitButton dataTestid="basicSubmitButton" label="Place Order" />;
};

export default SubmitOrderButton;
