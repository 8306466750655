import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import partition from 'lodash/partition';

import { CompletedOrder, CustomerQuery } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useTimeBasedRules } from 'src/public/components/online_ordering/TimeBasedRuleContext';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { MarketingSubscriptionChannel, useMarketing } from 'public/components/online_ordering/MarketingContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { sumByField } from 'public/components/online_ordering/reducerUtils';

import { getFirstOrThirdParty } from './loyalty/LoyaltyUtils';
import { useRefreshToastCashAmount } from './payment/ToastCashPaymentToggle';

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

/**
 * Check if all required fields are present, and have values which are valid in the given country (for now only checks phone number)
 * @param customer - The customer object to check
 * @param countryCode - The country code to validate the values against, namely the phone number
 * @returns true if all required fields are present and valid in the specified country, false otherwise
 * */
export const customerHasAllValidInfo = (customer: Customer, countryCode: CountryCode) => {
  return Boolean(customer?.firstName && customer?.lastName && customer?.email && customer?.phone)
    && isValidPhoneNumber(customer?.phone ?? '', countryCode);
};


export const getCustomerInfo = (customer: Customer) => {
  return {
    yourInfoPhone: customer?.phone || '',
    yourInfoEmail: customer?.email || '',
    yourInfoFirstName: customer?.firstName || '',
    yourInfoLastName: customer?.lastName || ''
  };
};

export const submitPhoneNumberImpl = async (
  phoneNumber: string,
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>,
  setSentCode: React.Dispatch<React.SetStateAction<boolean>>,
  setPasswordlessError: React.Dispatch<React.SetStateAction<string>>
) => {
  if(phoneNumber && isValidPhoneNumber(phoneNumber, 'US')) {
    setSentCode(true);
    if(!await passwordlessLogin(phoneNumber)) {
      setPasswordlessError('Error sending confirmation code');
      setSentCode(false);
    } else {
      setPasswordlessError('');
    }
  }
};


// Enums to standardize data values sent to the tracker

export enum AuthenticationStatus {
  Authenticated = 'Authenticated',
  Guest = 'Guest'
}

export enum AuthenticationSource {
  AccountCreation = 'Create a Toast account',
  AccountPage = 'Account page',
  UserNav = 'User nav',
  ExpressCheckout = 'Express checkout with Toast',
  GuestCheckout = 'Guest checkout page',
  LoyaltyCard = 'Loyalty card',
  LoyaltyCardOnConfirmationPage = 'Loyalty card on order confirmation page'
}

export function getAuthenticationStatus(customer: Customer | undefined) {
  return customer ? AuthenticationStatus.Authenticated : AuthenticationStatus.Guest;
}

export function getPaymentOption(paymentType: string | null, paymentOption: PaymentOption | null, giftCardAppliedAmount: number, orderTotal: number) {
  if(giftCardAppliedAmount === orderTotal) {
    return 'GC';
  }
  if(paymentType) {
    switch(paymentOption) {
      case PaymentOption.UponReceipt:
        return 'CASH';
      case PaymentOption.PayNow:
        return paymentType;
      default:
        return 'null';
    }
  }
  switch(paymentOption) {
    case PaymentOption.UponReceipt:
      return 'CASH';
    case PaymentOption.CreditCard:
      return 'CC';
    case PaymentOption.ApplePay:
      return 'APPLEPAY';
    default:
      return 'null';
  }
}


export const useHandleCompletedOrderCallback = () => {
  const tracker = useTracker();
  const history = useHistory();
  const { cartGuid, cart, clearCart } = useCart();
  const { paymentOption, tipAmount, paymentType } = usePayment();
  const { giftCardAppliedAmount, orderTotal } = useCheckout();
  const { confirmationPath } = useRestaurantRoutes();
  const { ooRestaurant, restaurant: { pixelsV2 } } = useRestaurant();
  const { rankedPromoOfferDiscounts } = useOffers();
  const { selectionsInCartWithTBRs, getItemLeadTime } = useTimeBasedRules();
  const { createMarketingSubscriptionRequest, subscribeToSmsMarketing, smsAccountEnabled, subscribeToEmailMarketing } = useMarketing();
  const userId = useExperimentUserId();
  const { ooToastCashSpend: ooToastCashSpendEnabled, ooEmailSubscriptionCheckboxEnabled } = useFlags();
  const refreshToastCashBalance = useRefreshToastCashAmount();


  return useCallback((completedOrder: CompletedOrder) => {
    const { customerV3 } = completedOrder;

    if(completedOrder?.guid) {
      if(cart?.order) {
        // It's safe to assume at this point that if the item has a lead time, its TBR is a lead time rule, and if it doesn't, its TBR is a preorder rule
        const [selectionsWithLeadTimeRules, selectionsWithPreorderRules] = partition(selectionsInCartWithTBRs, s => getItemLeadTime(s.itemGuid) !== undefined);
        tracker.trackConversion(cart, cartGuid!, pixelsV2 );
        tracker.track('Order placed', {
          restaurantGuid: cart!.restaurant!.guid,
          diningOption: cart.diningOptionBehavior,
          fulfillmentTime: cart.fulfillmentType,
          numItems: cart.order.numberOfSelections,
          subtotal: cart.order.preDiscountItemsSubtotal,
          tax: cart.order.taxV2,
          deliveryChargeTotal: cart.order.deliveryServiceCharges,
          gratuityServiceCharges: cart.order.gratuityServiceCharges,
          processingServiceCharges: cart.order.processingServiceCharges,
          nonDeliveryNonGratuityNonUbpServiceCharges: cart.order.nonDeliveryNonGratuityNonUbpServiceCharges,
          discounts: cart.order.discountsTotal,
          total: cart.order?.totalV2,
          paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal),
          giftCardApplied: giftCardAppliedAmount > 0,
          tipAmount,
          redemptionsAppliedToCheck: completedOrder.discounts.loyaltyDiscounts,
          firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName),
          leadTimeRuleItemCount: sumByField('quantity')(selectionsWithLeadTimeRules),
          leadTimeRuleTotalPrice: sumByField('price')(selectionsWithLeadTimeRules),
          preorderRuleItemCount: sumByField('quantity')(selectionsWithPreorderRules),
          preorderRuleTotalPrice: sumByField('price')(selectionsWithPreorderRules),
          useExperimentUserId: userId,
          smsAccountEnabled: smsAccountEnabled,
          smsConsentProvided: subscribeToSmsMarketing,
          smsSubscriptionIncompleteData: smsAccountEnabled && subscribeToSmsMarketing && (!customerV3 || !customerV3?.phone),
          emailConsentProvided: subscribeToEmailMarketing,
          numOffersPresent: rankedPromoOfferDiscounts?.length ?? 0,
          numPromoCodesUsed: cart.order.discounts.restaurantDiscount?.promoCode ? 1 : 0,
          isTds: !!completedOrder.deliveryServiceInfo,
          orderGuid: completedOrder.guid
        });
      }

      // IF SMS CHECKBOX CHECKED, SUBSCRIBE TO SMS
      if(subscribeToSmsMarketing && customerV3 && customerV3?.phone) {
        createMarketingSubscriptionRequest({
          customerInput: customerV3.phone,
          firstName: customerV3.firstName || '',
          lastName: customerV3.lastName || '',
          channel: MarketingSubscriptionChannel.SMS
        });
      }

      // IF EMAIL CHECKBOX CHECKED, SUBSCRIBE TO EMAIL
      if(subscribeToEmailMarketing && customerV3 && customerV3?.email && ooEmailSubscriptionCheckboxEnabled) {
        createMarketingSubscriptionRequest({
          customerInput: customerV3.email,
          firstName: customerV3.firstName || '',
          lastName: customerV3.lastName || '',
          channel: MarketingSubscriptionChannel.EMAIL
        });
      }

      clearCart();
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      if(ooToastCashSpendEnabled) {
        refreshToastCashBalance();
      }
      history.push(confirmationPath, { completedOrder });
    }
  }, [cart,
    subscribeToSmsMarketing,
    subscribeToEmailMarketing,
    clearCart,
    history,
    confirmationPath,
    selectionsInCartWithTBRs,
    tracker,
    cartGuid,
    pixelsV2,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    tipAmount,
    ooRestaurant?.loyaltyConfig?.programName,
    userId,
    smsAccountEnabled,
    getItemLeadTime,
    createMarketingSubscriptionRequest,
    rankedPromoOfferDiscounts,
    ooToastCashSpendEnabled,
    refreshToastCashBalance,
    ooEmailSubscriptionCheckboxEnabled]);
};
