import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { isPreviewSite } from 'src/lib/js/previewModeUtils';
import { useStaticSiteContext } from 'src/public/components/app/StaticSiteContext';

import { getImageUrl } from 'shared/components/common/Image';
import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { StructuredDataConsumer } from 'public/components/seo/context';

import { resources } from 'config';

import PixelsMeta, { PixelsMetaProps } from './PixelsMeta';
import StyleMeta, { StyleMetaProps } from './StyleMeta';
import UserWayMeta, { UserWayMetaProps } from './UserWayMeta';

type Props = {
  description?: string;
  icon: string;
  favicon?: string;
  title?: string;
  domain: string;
  titleTag?: string;
} & StyleMetaProps &
PixelsMetaProps &
UserWayMetaProps;

const Meta = (meta: Props) => {
  const restaurantContext = useOptionalRestaurant();
  const location = useLocation();
  const staticContext = useStaticSiteContext();
  const url = `https://${meta.domain}${location.pathname}`;
  const isPreviewMode = isPreviewSite(staticContext, restaurantContext?.restaurant?.config, restaurantContext?.toastProduct);
  const title = `${meta.title || restaurantContext?.selectedLocation?.name}${meta.titleTag ? ` | ${meta.titleTag}` : ''}`;
  const logoSrc = meta.favicon || meta.icon || restaurantContext?.ooRestaurant?.logoUrls?.small;
  const ogImgSrc = restaurantContext?.restaurant.config.ooConfig?.heroImage?.displaySrc || logoSrc;
  const externalModules = meta.externalModules;

  const ooOnly = restaurantContext?.restaurant.config.isOnlineOrderingOnly ?? true;
  const hasOOModule = restaurantContext?.ooRestaurant?.hasOnlineOrderingModule ?? true;
  const isNotProduction = process.env.NODE_ENV !== 'production';
  // dont index sites that are either in a non-prod env, preview mode, or oo-only sites that dont have the OO module
  const noIndex = isNotProduction || isPreviewMode || ooOnly && !hasOOModule;

  return (
    <>
      <Helmet>
        {noIndex ? <meta name="robots" content="noindex" /> : null}
        {meta.description ? <meta name="description" content={meta.description} /> : null}
        {meta.description ? <meta name="og:description" content={meta.description} /> : null}
        <link rel="canonical" href={url} />
        <meta name="og:url" content={url} />
        {title ? <title>{title}</title> : null}
        {title ? <meta name="og:title" content={title} /> : null}
        {ogImgSrc ? <meta name="og:image" content={getImageUrl(ogImgSrc, resources.imagePrefix)} /> : null}
        {logoSrc ? <link rel="icon" id="favicon" href={getImageUrl(logoSrc)} /> : null}
      </Helmet>
      <StructuredDataConsumer />
      <PixelsMeta pixels={meta.pixels} />
      <StyleMeta {...meta} />
      {externalModules &&
        <>
          <UserWayMeta externalModules={externalModules} />
        </>}
    </>
  );
};

export default Meta;
