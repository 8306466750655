import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { GOOGLE_FONTS } from '@toasttab/sites-components';
import ColorContrastChecker from 'color-contrast-checker';

import { Theme } from 'src/apollo/sites';

import { getImageUrl } from 'shared/components/common/Image';

type HexColor = `#${string}`;

export type StyleMetaProps = {
  primaryColor?: string;
  backgroundColor?: string;
  textColor?: string;
  secondaryTextColor?: string;
  fontFamily?: { name: string; url?: string } | string;
  headerFontFamily?: { name: string; url?: string } | string;
  siteTheme?: Partial<Theme> | null;
}

export const DEFAULT_COLORS: {[purpose: string]: HexColor} = {
  primary: '#000000',
  background: '#FFFFFF',
  text: '#252525',
  secondaryText: '#FFFFFF',
  link: '#2B4FB9'
};

const DEFAULT_FONT = 'Effra';


export const getFontFaceAndFamily = (fontData?: string | { name: string, url?: string | null }) => {
  if(!fontData) {
    return { fontFamily: _quoteFontFamily(DEFAULT_FONT), fontFace: '' };
  } else if(typeof fontData === 'string') {
    // Strip `sans-serif` from "Effra, sans-serif". Effra is the only case that has ', sans-serif' affixed to it.
    // TODO: remove `, sans-serif` from Admin SPA, Sites API, and create a DB migration to remove it from all site entries.
    const family = fontData === 'Effra, sans-serif' ? 'Effra' : fontData;
    return { fontFamily: _quoteFontFamily(family), fontFace: '' };
  }

  if(!fontData.url) {
    const family = fontData.name === 'Effra, sans-serif' ? 'Effra' : fontData.name;
    return { fontFamily: _quoteFontFamily(family), fontFace: '' };
  }

  const fontFace = `
    @font-face {
      font-family: ${_quoteFontFamily(fontData.name)};
      src: url('${getImageUrl(fontData.url)}');
    }
  `;

  return { fontFamily: _quoteFontFamily(fontData.name), fontFace };
};

const _quoteFontFamily = (fontFamily: string) => {
  return fontFamily.startsWith('"') ? fontFamily : `"${fontFamily}"`;
};

const parseColorHues = (color?: string) => color?.match(/^(#)?([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})/)?.slice(2);

export const getSiteStyles = (meta: StyleMetaProps) => {
  const contrastChecker = new ColorContrastChecker();

  const primaryHues = parseColorHues(meta.primaryColor);
  const primaryColor = getColor(meta.primaryColor) || DEFAULT_COLORS.primary;
  const background = getColor(meta.backgroundColor) || DEFAULT_COLORS.background;
  const text = getColor(meta.textColor) || DEFAULT_COLORS.text;
  const secondaryText = getColor(meta.secondaryTextColor) || DEFAULT_COLORS.secondaryText;
  const hoverColor = getColor(primaryHues
    ?.map(hue => Math.max(parseInt(hue, 16) - 20, 0))
    ?.map(hue => hue.toString(16).padStart(2, '0'))
    ?.join('')) || DEFAULT_COLORS.primary;

  const contrastingColors: HexColor[] = ['#FFFFFF', '#000000'];

  const textContrastingColor = [primaryColor, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(text, color, 14))[0];
  const primaryContrastingColor = [text, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(primaryColor, color, 14))[0] || '#FFFFFF';
  const backgroundContrastingColor = [primaryColor, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(background, color, 14))[0];
  const whiteContrastingColor = [primaryColor, text, '#000000'].filter(color => contrastChecker.isLevelAAA('#FFFFFF', color, 14))[0];

  const bodyFont = getFontFaceAndFamily(meta.fontFamily);
  const headerFont = getFontFaceAndFamily(meta.headerFontFamily || meta.fontFamily);

  return {
    primaryColor,
    background,
    text,
    secondaryText,
    hoverColor,
    textContrastingColor,
    primaryContrastingColor,
    backgroundContrastingColor,
    whiteContrastingColor,
    bodyFont,
    headerFont
  };
};

const siteThemeToCssVars = (siteTheme: StyleMetaProps['siteTheme']): string => {
  if(siteTheme && siteTheme.enabled && siteTheme.colorScheme) {
    return `
    /* Prevent CSS conflicts in Toast Admin by prefixing variables with 'DS-' (Digital Storefront) */
    
    --ds-surface-default: ${siteTheme.colorScheme.surface.default};
    --ds-surface-secondary: ${siteTheme.colorScheme.surface.secondary};
    --ds-surface-tertiary: ${siteTheme.colorScheme.surface.tertiary};
    --ds-surface-focus: ${siteTheme.colorScheme.surface.focus};
    --ds-surface-overlay: ${siteTheme.colorScheme.surface.overlay};
    --ds-surface-inverse: ${siteTheme.colorScheme.surface.inverse};
    --ds-surface-shadow: ${siteTheme.colorScheme.surface.shadow};
    
    --ds-text-default: ${siteTheme.colorScheme.text.default};
    --ds-text-secondary: ${siteTheme.colorScheme.text.secondary};
    --ds-text-action: ${siteTheme.colorScheme.text.action};
    --ds-text-on-bg-primary: ${siteTheme.colorScheme.text.onBgPrimary};
    --ds-text-on-bg-primary-subtle: ${siteTheme.colorScheme.text.onBgPrimarySubtle};
    --ds-text-on-bg-secondary: ${siteTheme.colorScheme.text.onBgSecondary};
    --ds-text-on-bg-secondary-subtle: ${siteTheme.colorScheme.text.onBgSecondarySubtle};
    --ds-text-on-overlay: ${siteTheme.colorScheme.text.onOverlay};
    --ds-text-on-error-primary: ${siteTheme.colorScheme.text.onErrorPrimary};
    --ds-text-on-error-secondary: ${siteTheme.colorScheme.text.onErrorSecondary};
    
    --ds-icon-default: ${siteTheme.colorScheme.icon.default};
    --ds-icon-secondary: ${siteTheme.colorScheme.icon.secondary};
    --ds-icon-action: ${siteTheme.colorScheme.icon.action};
    --ds-icon-on-bg-primary: ${siteTheme.colorScheme.icon.onBgPrimary};
    --ds-icon-on-bg-primary-subtle: ${siteTheme.colorScheme.icon.onBgPrimarySubtle};
    --ds-icon-on-bg-secondary: ${siteTheme.colorScheme.icon.onBgSecondary};
    --ds-icon-on-bg-secondary-subtle: ${siteTheme.colorScheme.icon.onBgSecondarySubtle};
    --ds-icon-on-overlay: ${siteTheme.colorScheme.icon.onOverlay};
    --ds-icon-on-error-primary: ${siteTheme.colorScheme.icon.onErrorPrimary};
    --ds-icon-on-error-secondary: ${siteTheme.colorScheme.icon.onErrorSecondary};
    
    --ds-background-primary: ${siteTheme.colorScheme.background.primary};
    --ds-background-primary-overlay: ${siteTheme.colorScheme.background.primaryOverlay};
    --ds-background-primary-focus: ${siteTheme.colorScheme.background.primaryFocus};
    --ds-background-primary-subtle: ${siteTheme.colorScheme.background.primarySubtle};
    --ds-background-primary-subtle-focus: ${siteTheme.colorScheme.background.primarySubtleFocus};
    --ds-background-secondary: ${siteTheme.colorScheme.background.secondary};
    --ds-background-secondary-overlay: ${siteTheme.colorScheme.background.secondaryOverlay};
    --ds-background-secondary-focus: ${siteTheme.colorScheme.background.secondaryFocus};
    --ds-background-secondary-subtle: ${siteTheme.colorScheme.background.secondarySubtle};
    --ds-background-secondary-subtle-focus: ${siteTheme.colorScheme.background.secondarySubtleFocus};
    
    --ds-border-default: ${siteTheme.colorScheme.border.default};
    --ds-border-focus: ${siteTheme.colorScheme.border.focus};
    --ds-border-with-primary: ${siteTheme.colorScheme.border.withPrimary};
    --ds-border-with-primary-subtle: ${siteTheme.colorScheme.border.withPrimarySubtle};
    --ds-border-with-secondary: ${siteTheme.colorScheme.border.withSecondary};
    --ds-border-with-secondary-subtle: ${siteTheme.colorScheme.border.withSecondarySubtle};
    
    --ds-override-nav-text: ${siteTheme.colorOverrides?.navigation?.text};
    --ds-override-nav-background: ${siteTheme.colorOverrides?.navigation?.background};
    
    --ds-override-view-only-menu-item-title: ${siteTheme.colorOverrides?.viewOnlyMenu?.itemTitle};
    --ds-override-view-only-menu-item-description: ${siteTheme.colorOverrides?.viewOnlyMenu?.itemDescription};
    --ds-override-view-only-menu-card-background: ${siteTheme.colorOverrides?.viewOnlyMenu?.cardBackground};
    
    --ds-override-oo-menu-item-title: ${siteTheme.colorOverrides?.onlineOrderingMenu?.itemTitle};
    --ds-override-oo-menu-item-description: ${siteTheme.colorOverrides?.onlineOrderingMenu?.itemDescription};
    --ds-override-oo-menu-card-background: ${siteTheme.colorOverrides?.onlineOrderingMenu?.cardBackground};

    --ds-override-footer-background: ${siteTheme.colorOverrides?.footerColorOverrides?.background};
    --ds-override-footer-text: ${siteTheme.colorOverrides?.footerColorOverrides?.text};
  `;
  } else {
    return '';
  }
};

const StyleMeta = ({ siteTheme, ...meta }: StyleMetaProps) => {
  const {
    primaryColor, background, text, secondaryText, hoverColor, textContrastingColor,
    primaryContrastingColor, backgroundContrastingColor, whiteContrastingColor, bodyFont, headerFont
  } = getSiteStyles(meta);

  const dsThemeSchemeCssVars = useMemo(() => {
    return siteThemeToCssVars(siteTheme);
  }, [siteTheme]);

  return (
    <Helmet>
      {typeof meta.fontFamily !== 'string' && meta.fontFamily?.name
  && GOOGLE_FONTS.includes(meta.fontFamily.name)
        ? <link href={`https://fonts.googleapis.com/css?family=${meta.fontFamily.name.replace(/ /g, '+')}:wght@400;600;700&display=swap`} rel="stylesheet" />
        : null}
      {typeof meta.headerFontFamily !== 'string' && meta.headerFontFamily?.name
  && GOOGLE_FONTS.includes(meta.headerFontFamily.name)
        ? <link href={`https://fonts.googleapis.com/css?family=${meta.headerFontFamily.name.replace(/ /g, '+')}:wght@400;600;700&display=swap`} rel="stylesheet" />
        : null}
      <style type="text/css">
        {`
  ${bodyFont.fontFace}
  ${headerFont.fontFace}

  :root {
    /* DS Theme Scheme -- Prevent CSS conflicts in Toast Admin by prefixing variables with 'DS-' (Digital Storefront) */
    ${dsThemeSchemeCssVars}
  
    /* DEPRECATED -- Legacy theme variables -- USED ONLY FOR BACKWARDS COMPATIBILITY */
    --tsw-background-color: ${background};
    --tsw-background-color-med: ${background}40;
    --tsw-background-contrasting-color: ${backgroundContrastingColor}; /* Guaranteed to contrast with --tsw-background-color */
    --tsw-background-contrasting-color-50: ${backgroundContrastingColor}66;

    --tsw-primary-color: ${primaryColor};
    --tsw-primary-color-90: ${primaryColor}cc;
    --tsw-primary-color-75: ${primaryColor}aa;
    --tsw-primary-color-50: ${primaryColor}66;
    --tsw-primary-color-25: ${primaryColor}22;
    --tsw-primary-contrasting-color: ${primaryContrastingColor}; /* Guaranteed to contrast with --tsw-primary-color */
    --tsw-primary-contrasting-color-50: ${primaryContrastingColor}66;
    --tsw-primary-hover-color: ${hoverColor};
    --tsw-primary-hover-color-25: ${primaryColor}25;

    --tsw-primary-text-color: ${text};
    --tsw-primary-text-color-90: ${text}cc;
    --tsw-primary-text-color-75: ${text}aa;
    --tsw-primary-text-color-50: ${text}66;
    --tsw-primary-text-color-25: ${text}22;
    --tsw-primary-text-contrasting-color: ${textContrastingColor}; /* Guaranteed to contrast with --tsw-primary-text-color */
    --tsw-primary-text-contrasting-color-50: ${textContrastingColor}66;

    --tsw-white-contrasting-color: ${whiteContrastingColor}; /* A user color that contrasts with white */
    --tsw-white-contrasting-color-75: ${whiteContrastingColor}aa;
    --tsw-white-contrasting-color-50: ${whiteContrastingColor}66;

    --tsw-secondary-text-color: ${secondaryText};
    --tsw-link-color: ${text === DEFAULT_COLORS.text ? DEFAULT_COLORS.link : text};
    --tsw-font-family: ${bodyFont.fontFamily};
    --tsw-header-font-family: ${headerFont.fontFamily};
  }
  `}
      </style>
    </Helmet>);
};


const getColor = (color?: string): HexColor | null => {
  const hues = parseColorHues(color);
  return hues?.length ? `#${hues.join('')}` : null;
};

export default StyleMeta;
